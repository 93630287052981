import styled from '@emotion/styled'
import React, { useState } from 'react'
import DownArrow from '../../../images/arrow_down.svg'
import UpArrow from '../../../images/arrow_up.svg'
import { motion, AnimatePresence } from "framer-motion";

const DetailsContainer = styled.div`
  padding: 24px 0;
  ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '#f6f3ef')};
`
const DetailsHeader = styled.h2`
  padding-top: 40px;
  font-size: 32px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.17px;
  text-align: center;
  color: #000000;
  padding: 0 10px;
`
const ExpandContainers = styled.div`
  margin: 16px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SectionContainer = styled.div`
  margin-bottom: 8px;
  border-bottom: solid 0.01em rgba(0,0,0,0.35);
  width: 75%;
  ${({ fullWidth }) => (fullWidth === true ? 'width: 100%' : '')};
`
const HeaderSection = styled.div`
  cursor: pointer;
  padding-top: 28px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  font-weight: 500;
  user-select: none;
`
const TitleContainer = styled.span`
  max-width: calc(100% - 24px - 8px);
  font-family: Objectivity;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
`
const ExpandIcon = styled.img`
  width: 18px !important;
  height: 18px !important;
`
const collapsed = (props) => {
  if (props.collapsed === true) {
    return `
          max-height: 0px;
      `
  }
}
const CollapsableContent = styled(motion.div)`
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor};` : '#f6f3ef')}
  padding: 8px 8px 8px 8px;
  ${({ long }) =>
    long === true
      ? `
    max-height: 900px;
  `
      : `
    max-height: 600px;
  `}
  overflow: hidden;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.85);
  ${(props) => collapsed(props)};

  & a {
    text-decoration: underline !important;
  }

  & ul {
    list-style-type: '— ';
    margin: 16px 0;
    padding: 0 0 0 16px;
  }

  & p {
    margin: 16px 0;
  }
`
export const ExpandSection = ({ title, body, init, long, backgroundColor, fullWidth }) => {
  const initOpen = init === undefined ? true : init
  let [open, setOpen] = useState(initOpen)
  return (
    <SectionContainer fullWidth={fullWidth}>
      <HeaderSection collapsed={open} onClick={() => setOpen((prev) => !prev)}>
        <TitleContainer>{title}</TitleContainer>
        <ExpandIcon src={!open ? UpArrow : DownArrow} />
      </HeaderSection>
      <CollapsableContent long={long} collapsed={open} backgroundColor={backgroundColor}>
        {typeof body === 'string' || body instanceof String ? (
          <div dangerouslySetInnerHTML={{ __html: open ? body : body }} />
        ) : (
          <div> {body} </div>
        )}
      </CollapsableContent>
    </SectionContainer>
  )
}

const AccordionBlock = ({ data, fullWidth }) => {
  const [activeIndices, setActiveIndices] = useState([]);

  const toggleIndex = (index) => {
    const isActive = activeIndices.includes(index);
    setActiveIndices((prevIndices) =>
      isActive
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  return (
    <DetailsContainer backgroundColor={data.BackgroundColor}>
      <DetailsHeader>{data.Title}</DetailsHeader>
      <ExpandContainers>
        {data.AccordionItem.map((val, key) => {
          const isOpen = activeIndices.includes(key);
          return (
            <SectionContainer fullWidth={fullWidth} key={key}>
              <HeaderSection onClick={() => toggleIndex(key)}>
                <TitleContainer>{val.AccordionTitle}</TitleContainer>
                <ExpandIcon src={!isOpen ? UpArrow : DownArrow} />
              </HeaderSection>
              <AnimatePresence>
                {isOpen && (
                  <CollapsableContent
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: {
                        height: "auto",
                        opacity: 1,
                      },
                      collapsed: {
                        height: 0,
                        opacity: 0,
                      },
                    }}
                    transition={{ duration: 0.15, ease: [0.04, 0.62, 0.23, 0.98] }}
                    long={val.long}
                    backgroundColor={data.BackgroundColor}
                  >
                    {typeof val.AccordionCopy === 'string' || val.AccordionCopy instanceof String ? (
                      <div dangerouslySetInnerHTML={{ __html: val.AccordionCopy }} />
                    ) : (
                      <div>{val.AccordionCopy}</div>
                    )}
                  </CollapsableContent>
                )}
              </AnimatePresence>
            </SectionContainer>
          );
        })}
      </ExpandContainers>
    </DetailsContainer>
  );
};

export default AccordionBlock
