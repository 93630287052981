import styled from '@emotion/styled'
import React from 'react'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: 80vh;
`

function AttentiveForm({ data }) {
  return (
    <>
      <Container>
        <Iframe src={data.landingPageUrl} key={data.id} />
      </Container>
    </>
  )
}

export default AttentiveForm
