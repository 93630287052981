import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 32px;
  @media (max-width: 600px) {
    margin: 12px;
  }

  .oke-state-empty {
    display: none;
  }

  .oke-w-controls-nav {
    display: none !important;
  }

  --oke-border-width: 1px;
  --oke-border-color: #0004;
  --oke-shadingColor: #fff;
  --oke-button-backgroundColorHover: #fff;
  --oke-button-backgroundColor: #fff;
  --oke-button-borderColor: #000;
  --oke-stars-foregroundColor: #000;
  --oke-stars-foregroundColor: #000a !important;
  --oke-text-primaryColor: #000;

  & .oke-stars-foreground {
    --oke-stars-foregroundColor: #000a !important;
  }

  & .oke-star-filled {
    --oke-stars-foregroundColor: #000a !important;
  }
  & .oke-w, oke-modal{
    --oke-stars-foregroundColor: #000a !important;
  }
  & .oke-w-review-side {
    border: none !important;
  }
  & .oke-product-thumbnail-image {
    border: none !important;
  }
`
const ExploreHeaderContainer = styled.div`
  height: 200px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1025px) {
    margin: 20px 0;
  }
`
const ExploreHeading = styled.h2`
  font-size: 42px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -1.17px;
  text-align: center;
  color: #000000;
`

const ReviewPanel = ({ data }) => {
  const widgetContainer = useRef(null)

  const initialiseReviewsWidget = () => {
    if (widgetContainer?.current) {
      window?.okeWidgetApi?.initWidget(widgetContainer.current)
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      initialiseReviewsWidget()
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <ReviewContainer>
      <ExploreHeaderContainer>
        {data?.Title && (
          <ExploreHeading dangerouslySetInnerHTML={{ __html: data?.Title }} />
        )}
      </ExploreHeaderContainer>
      <div
        ref={widgetContainer}
        data-oke-widget
        data-oke-reviews-product-id={`shopify-${data?.shopifyProductId}`}
      ></div>
      <br />
    </ReviewContainer>
  )
}

export default ReviewPanel
