import styled from '@emotion/styled'
import React from 'react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import Video from '../../components/panels/Video'

const ContentBlockData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 60px;
  width: 50%;

  @media (min-width: 1026px) {
    ${(props) => (props.contentLeft ? 'justify-content: start; align-items: start;' : 'justify-content: end; align-items: end;')};
  }

  @media (max-width: 1025px) {
    padding: 20px 16px;
    width: 50%;
    ${(props) => (props.contentLeft ? 'justify-content: start; align-items: start;' : 'justify-content: end; align-items: end;')};
  }
`
const LeftImageContainer = styled(GatsbyImage)`
  height: 400px;
  width: 300px;
  border-radius: 5px;
  @media (max-width: 1025px) {
    height: 150px;
    width: 150px;
    border-radius: 5px;
  }
`
const RightImageContainer = styled(GatsbyImage)`
  height: 600px;
  width: 500px;
  border-radius: 5px;
  @media (max-width: 1025px) {
    height: 300px;
    width: 250px;
    border-radius: 5px;
  }
`
const LeftVideoContainer = styled(Video)`
  height: 400px;
  width: 300px;
  border-radius: 5px;
  @media (max-width: 1025px) {
    height: 150px;
    width: 150px;
    border-radius: 5px;
  }
`
const RightVideoContainer = styled(Video)`
  height: 600px;
  width: 500px;
  border-radius: 5px;
  @media (max-width: 1025px) {
    height: 300px;
    width: 250px;
    border-radius: 5px;
  }
`
const Content = styled.div`
  display: flex;
  background-color: #1E392A;
`

const DualImageBlock = ({ data }) => {
  return (
    <Content>
      <ContentBlockData contentLeft={true}>
        {
          data?.LeftImage?.mime?.includes('video') ? (
            <RightVideoContainer objectFit='cover' alt='Splash screen'
              src={data?.LeftImage?.file?.publicURL}
            />
          ) : (
            <RightImageContainer objectFit='cover' alt='Splash screen'
              image={
                withArtDirection(
                  getImage(data?.LeftImage?.file?.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(data?.LeftImage?.file?.childImageSharp?.gatsbyImageDataMobile),
                    },
                  ]
                )} />
          )
        }
      </ContentBlockData>
      <ContentBlockData contentLeft={false}>
        {
          data?.RightImage?.mime?.includes('video') ? (
            <LeftVideoContainer objectFit='cover' alt='Splash screen'
              src={data?.RightImage?.file?.publicURL}
            />
          ) : (
            <LeftImageContainer objectFit='cover' alt='Splash screen'
              image={
                withArtDirection(
                  getImage(data?.RightImage?.file?.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(data?.RightImage?.file?.childImageSharp?.gatsbyImageDataMobile),
                    },
                  ]
                )} />
          )
        }
      </ContentBlockData>
    </Content>
  )
}

export default DualImageBlock
