import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import Video from '../Video'
import Link from '../../Link'
import { debounce } from 'lodash'

const MaxHeightImage = styled(GatsbyImage)`
  width: 100%;
  aspect-ratio: ${(props) => props.aspectratio && props.aspectratio};
`
const MaxHeightVideo = styled(Video)`
`
const ImageContainer = styled.div`
  @media (min-width: 769px) {
    max-width: ${(props) => props.maxwidth ? '80%' : '100%'};
    height: ${(props) => props.height ? `${props.height}vh` : 'auto'};;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }
`
const HeaderCopyHeading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  z-index: 2;
  max-width: 1200px;
  width: 100vw;
  font-family: ${(props) => props.fontFamily ? `${props.fontFamily}` : ''};;
  font-size: ${(props) => props.fontSize ? `${props.fontSize}px` : '41px'};;
  font-weight: ${(props) => props.fontWeight ? `${props.fontWeight}` : '400'};;
  color: ${(props) => props.textColor ? `${props.textColor}` : 'white'};;
  font-style: ${(props) => props.fontFamily === "PPEditorialNew" ? `italic` : 'normal'};;

  @media (max-width: 768px) {
    font-size: 32px;
    width: 80vw;
  }
`
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`
const TopLevelContainer = styled.div`
  position: relative;
  margin: ${(props) => props.maxwidth ? '64px' : ''};

  @media (max-width: 1025px) {
    margin: ${(props) => props.maxwidth ? '16px' : ''};
  }
`

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 200);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const FullWidthImage = ({ data }) => {
  const filename = data?.OptionalMobileImage?.name?.slice(0, data?.OptionalMobileImage?.name?.lastIndexOf('.'));
  let size = useWindowSize();

  return (
    <Link to={data?.linkTo} noLink={data?.linkTo === null}>
      <div id={filename} />
      <TopLevelContainer maxwidth={data.MaxWidthImage}>
        <ImageContainer maxwidth={data.MaxWidthImage} height={data.height}>
          {data.Image?.isStatic ? (
            <StaticHeaderImage src={size.width > 1025 ? data.Image?.url : data?.OptionalMobileImage?.url} />
          ) : (
            <>
              {size.width > 1025 ? (
                <>
                  {data.Image?.mime.includes('video') ? (
                    <MaxHeightVideo
                      padding={data.ImagePadding}
                      alt={data.Image?.alternativeText || data.Image?.name}
                      src={data.Image?.file.publicURL}
                      loading={data?.LazyLoading === false ? 'eager' : 'lazy'}
                    />
                  ) : (
                    data.Image?.file?.childImageSharp?.gatsbyImageData ? (
                      <MaxHeightImage
                        style={{ padding: 0, margin: 0 }}
                        height={data.height}
                        objectPosition="center bottom"
                        alt={data.Image?.alternativeText || data.Image?.name}
                        aspectratio={data.Image?.file?.childImageSharp?.gatsbyImageData?.width / data.Image?.file?.childImageSharp?.gatsbyImageData?.height}
                        image={data.Image?.file?.childImageSharp?.gatsbyImageData}
                        loading={data?.LazyLoading === false ? 'eager' : 'lazy'}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </>
              ) : (
                <>
                  {data?.OptionalMobileImage?.mime.includes('video') ? (
                    <MaxHeightVideo
                      padding={data.ImagePadding}
                      alt={data?.OptionalMobileImage?.alternativeText || data?.OptionalMobileImage?.name}
                      src={data?.OptionalMobileImage.file.publicURL}
                      loading={data?.LazyLoading === false ? 'eager' : 'lazy'}
                    />
                  ) : (
                    data?.OptionalMobileImage?.file?.childImageSharp?.gatsbyImageData ? (
                      <MaxHeightImage
                        loading={data?.LazyLoading === false ? 'eager' : 'lazy'}
                        style={{ padding: 0, margin: 0 }}
                        height={data.height}
                        objectPosition="center bottom"
                        alt={data?.OptionalMobileImage?.alternativeText || data?.OptionalMobileImage?.name}
                        aspectratio={data?.OptionalMobileImage?.file?.childImageSharp?.gatsbyImageData?.width / data?.OptionalMobileImage?.file?.childImageSharp?.gatsbyImageData?.height}
                        image={data?.OptionalMobileImage?.file?.childImageSharp?.gatsbyImageData}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </>
              )}
            </>
          )}
        </ImageContainer>
        {data?.OverlayText && (
          <HeaderCopyHeading
            dangerouslySetInnerHTML={{ __html: data.OverlayText }}
            fontSize={data?.MainFontSize}
            fontWeight={data?.MainFontWeight}
            fontFamily={data?.MainFont}
            textColor={data?.MainTextColor}
          />
        )}
      </TopLevelContainer>
    </Link>
  );
};

export default FullWidthImage;
