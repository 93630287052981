import styled from '@emotion/styled'

export const Container = styled.div`
display: flex;
`
export const Dec = styled.div`
display: flex;
padding: 13px 0px;
padding-right: 8px;
cursor: pointer;
color: rgb(var(--silver));
`
export const Num = styled.div`
display: flex;
padding: 13px 2px;
color: white;
`
export const Inc = styled.div`
display: flex;
padding: 13px 0px;
padding-left: 8px;
cursor: pointer;
color: rgb(var(--silver));
`
export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-bottom: solid 1px #929292;
`
export const ImageContainer = styled.div`
  width: 20%;
  height: 100%;
  display: inline-block;
`
export const BodyContainer = styled.span`
  width: 80%;
  height: 100%;
  display: inline-block;
`
export const TitleSpan = styled.span`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
`
export const VarientSpan = styled.div`
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0.21px;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
`
export const ItemContainerTop = styled.div`
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -0.41px;
  color: rgba(0, 0, 0, 0.85);
`