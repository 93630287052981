import { useState, useEffect } from 'react';

const useFavoriteProducts = () => {
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem('wishlist')) || [];
    setFavoriteProducts(favorites);
  }, []);

  const removeFavoriteProduct = (productId) => {
    const updatedFavorites = favoriteProducts.filter(item => item !== productId);
    setFavoriteProducts(updatedFavorites);
    const favorites = JSON.parse(localStorage.getItem('wishlist')) || [];
    localStorage.setItem('wishlist', JSON.stringify(favorites.filter(item => item !== productId)));
  };

  const addFavoriteProduct = (productId) => {
    if (!favoriteProducts.includes(productId)) {
      const updatedFavorites = [...favoriteProducts, productId];
      setFavoriteProducts(updatedFavorites);
      const favorites = JSON.parse(localStorage.getItem('wishlist')) || [];
      localStorage.setItem('wishlist', JSON.stringify([...favorites, productId]));
    }
  };

  return [favoriteProducts, addFavoriteProduct, removeFavoriteProduct];
};

export default useFavoriteProducts;
