import React from 'react'
import { Slice } from 'gatsby'

const ShopPanel = ({ data }) => {
  return (
    <Slice
        panelData={data}
        alias={`shop_panel`}
    />
  )
}

export default ShopPanel
