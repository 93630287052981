import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'

const Fade = ({ children, noFade }) => {
  if (noFade) return children
  return (
    <div
      classNameNotInView="vis-hidden"
      classNameInView="animate__animated animate__fadeIn"
    >
      {children}
    </div>
  )
}

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;

  ${({ noMobile }) =>
    noMobile &&
    `
    @media (min-width : 769px){
      display: none;
    }
  `}
  ${({ noDesktop }) =>
    noDesktop &&
    `
    @media (max-width : 768px){
      display: none;
    }
  `}
`

const StyledVideo = styled.video`
  max-width: 200%;
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.objectFit ? `contain;` : 'cover;')};
  ${(props) => (props.objectPosition ? `object-position: bottom;` : '')};
  ${(props) => (props.margin ? 'margin: 5%;' : '')};
`

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const observerRef = useRef(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    )
  }, [])

  useEffect(() => {
    observerRef.current.observe(ref.current)

    return () => {
      observerRef.current.disconnect()
    }
  }, [ref])

  return isOnScreen
}

const InfoSections = ({
  src,
  posterSrc,
  styles,
  noFade = true,
  style,
  noMobile,
  noDesktop,
  className,
  loading = 'lazy',
  margin,
  objectFit,
  objectPosition,
  videoStyle
}) => {
  const videoRef = useRef()
  const isOnScreen = useOnScreen(videoRef)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (isOnScreen === true && loaded === false && loading === 'lazy') {
      let video = videoRef.current
      video.load()
      video.autoplay = true

      setLoaded(true)
    }
  }, [isOnScreen])

  return (
    <VideoContainer
      key={src}
      noMobile={noMobile}
      noDesktop={noDesktop}
      style={{ ...styles, ...style }}
      className={className}
    >
      <StyledVideo
        preload={loading === 'lazy' ? 'none' : 'yes'}
        autoPlay={loading === 'lazy' ? 'none' : 'yes'}
        ref={videoRef}
        muted
        loop
        controls={false}
        playsInline
        poster={posterSrc}
        margin={margin}
        objectFit={objectFit}
        objectPosition={objectPosition}
        style={{ ...videoStyle }}
      >
        <source src={((loading === 'lazy' && loaded) || loading !== 'lazy') ? src : ''} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos...
      </StyledVideo>
    </VideoContainer>
  )
}

export default InfoSections
