import React, { useState } from 'react'
import './index.css'

const prices = {
  'en-AU':  [[300, 30], [600, 100], [1000, 200]],
  'en':     [[300, 10], [500, 15], [1000, 20]],
  'en-NZ':  [[400, 40], [800, 100], [1200, 240]],
  'en-GB':  [[250, 10], [500, 15], [800, 20]],
  'en-CA':  [[400, 10], [600, 15], [1000, 20]],
}

const ProgressBar = ({ total, itemCount, storeLocale }) => {
  let totalCount = 0
  if (itemCount) {
    totalCount = itemCount.reduce(
      (accumulator, a) => accumulator + (a[1].includes('SET') ? 2 : 1 * a[0]),
      0
    )
  }

  const priceRanges = prices[storeLocale] || [[], [], []]
  const currencySign = storeLocale === 'en-GB'? '£' : '$'

  return (
    <>
      {(total < priceRanges[0][0] || totalCount <= 1) && (
        <div className="progress-bar">
          <div
            className="filler"
            style={{
              width: `${(total / priceRanges[0][0]) * 100}%`,
              maxWidth: '100%',
            }}
          />
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: '100%',
              textAlign: 'center',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                color: '#5F3E1A',
                textShadow: '-1px 1px 5px rgb(0 0 0 / 20%)',
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {totalCount <= 1 ? (
                <>Add two items to cart to get {['en-AU', 'en-NZ'].includes(storeLocale) && currencySign}{priceRanges[0][1]}{['en', 'en-GB'].includes(storeLocale) && '%'} off.</>
              ) : (
                <>Spend {currencySign}{priceRanges[0][0] - total} more to save {['en-AU', 'en-NZ'].includes(storeLocale) && currencySign}{priceRanges[0][1]}{['en', 'en-GB'].includes(storeLocale) && '%'}</>
              )}

            </span>
          </div>
        </div>
      )}

      {total < priceRanges[1][0] && totalCount > 1 && !(total < priceRanges[0][0]) && (
        <div className="progress-bar">
          <div
            className="filler"
            style={{ width: `${(total / priceRanges[1][0]) * 100}%` }}
          />
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: '100%',
              textAlign: 'center',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                color: '#5F3E1A',
                textShadow: '-1px 1px 5px rgb(0 0 0 / 20%)',
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {' '}
              Spend {currencySign}{priceRanges[1][0] - total} more to save {['en-AU', 'en-NZ'].includes(storeLocale) && currencySign}{priceRanges[1][1]}{['en', 'en-GB'].includes(storeLocale) && '%'}{' '}
            </span>
          </div>
        </div>
      )}

      {!(total < priceRanges[1][0] || total < priceRanges[0][0]) && totalCount > 1 && (
        <div className="progress-bar">
          <div
            className="filler"
            style={{
              width: `${(total / priceRanges[2][0]) * 100}%`,
              maxWidth: '100%',
            }}
          />
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: '100%',
              textAlign: 'center',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                color: '#5F3E1A',
                textShadow: '-1px 1px 5px rgb(0 0 0 / 20%)',
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {total < priceRanges[2][0] ? (
                <> Spend {currencySign}{priceRanges[2][0] - total} more to save {['en-AU', 'en-NZ'].includes(storeLocale) && currencySign}{priceRanges[2][1]}{['en', 'en-GB', 'en-CA'].includes(storeLocale) && '%'} </>
              ) : (
                <> You are saving {['en-AU', 'en-NZ'].includes(storeLocale) && currencySign}{priceRanges[2][1]}{['en', 'en-GB', 'en-CA'].includes(storeLocale) && '%'} </>
              )}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default ProgressBar