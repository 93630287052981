const klavyioSignUp = async ({
  email,
  phone_number,
  signupID,
  source,
  additionalFields = {},
  first_name,
  last_name,
}) => {
  return await new Promise((resolve, reject) => {
    let klavSrc
    if (window.location.href.includes('/au/')) {
      klavSrc = 'Q36jMX'
    } else if (window.location.href.includes('/us/')) {
      klavSrc = 'WZ7nUn'
    } else if (window.location.href.includes('/nz/')) {
      klavSrc = 'TxgKTr'
    } else if (window.location.href.includes('/uk/')) {
      klavSrc = 'SA68Lu'
    } else if (window.location.href.includes('/ca/')) {
      klavSrc = 'WsmHP3'
    } else {
      klavSrc = 'Q36jMX'
    }
    const offset = new Date().getTimezoneOffset() / -60
    var myHeaders = new Headers();
    myHeaders.append("revision", "2023-10-15");
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      data: {
        type: 'subscription',
        attributes: {
          custom_source: source,
          profile: {
            data: {
              type: 'profile',
              attributes: {
                ...(email ? { "email": email } : {}),
                ...(phone_number ? { "phone_number": phone_number } : {}),
                ...(first_name && { "first_name": first_name }),
                ...(last_name && { "last_name": last_name }),
                properties: {
                  "$consent_method": "Klaviyo Form",
                  "$consent_form_id": signupID,
                  "$consent_form_version": 780583,
                  "$timezone_offset": offset,
                },
              }
            }
          }
        },
        relationships: { list: { data: { type: 'list', id: signupID } } }
      }
    })
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${klavSrc}`, requestOptions)
      .then(response => response.text())
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}
export default klavyioSignUp;