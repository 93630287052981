import React from 'react'
import { Slice } from 'gatsby'

const IndividualDiscountPanel = ({ data }) => {
  return (
    <Slice
      panelData={data}
      alias={`individual_discount_panel`}
    />
  )
}

export default IndividualDiscountPanel
