import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';


const ColourContainer = styled.div`
  background-color: #F6F4EF;
  padding-bottom: 64px;
` 
const ImagesContainer = styled.div`
  position: relative;
  height: 800px;
  overflow: hidden;
  @media (max-width: 1025px) {
    height: 400px;
  }
`;

const ScrollContainer = styled.div`
  background-color: #F6F4EF;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: translate 0.1s;
`;

const ScrollContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 800px;
  @media (max-width: 1025px) {
    height: 400px;
  }
`;

const TempImageContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1025px) {
    padding: 8px;
  }
`;

const ImageLabel = styled.div`
  width: 400px;
  display: flex;
  text-align: start;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  font-family: monospace;
  font-size: 16px;
  @media (max-width: 1025px) {
    width: 150px;
  }
`;

const SingleImage = styled(GatsbyImage)``;

const ImageContainer = styled.div`
  display: flex;
  align-items: ${({ align }) => align};
  border-radius: 5px;
  height: 100%;
`;

const ImageWithCaption = ({ image, index, align }) => {
  const isTopLabel = index % 3 === 2;

  return (
    <TempImageContainer align={align}>
      {isTopLabel && <ImageLabel marginTop="0" marginBottom={0}>{index < 9 ? `0${index + 1}` : index + 1}</ImageLabel>}
      <SingleImage
        image={withArtDirection(
          getImage(image?.file?.childImageSharp?.gatsbyImageData),
          [
            {
              media: '(max-width: 1024px)',
              image: getImage(image?.file?.childImageSharp?.gatsbyImageDataMobile),
            },
          ]
        )}
        alt={`Image ${index}`}
      />
      {!isTopLabel && <ImageLabel marginTop={'24px'} marginBottom="0">{index < 9 ? `0${index + 1}` : index + 1}</ImageLabel>}
    </TempImageContainer>
  );
};

const ImageCarousel = ({ data }) => {
  const getAlignment = (idx) => {
    switch (idx % 3) {
      case 0:
        return 'flex-start'; // Align top
      case 1:
        return 'flex-end'; // Align bottom with margin
      case 2:
        return 'flex-end'; // Align bottom without margin
      default:
        return 'flex-start';
    }
  };

  const scrollContainerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  const throttle = (func, limit) => {
    let inThrottle;
    return function() {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && scrollContainerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top + window.scrollY - 700;
        const scrollPosition = window.scrollY;
        const translateX = scrollPosition - containerTop;
        scrollContainerRef.current.style.transform = `translateX(${-translateX}px)`;
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 5);

    if (isVisible) {
      window.addEventListener('scroll', throttledHandleScroll);
    } else {
      window.removeEventListener('scroll', throttledHandleScroll);
    }

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <ColourContainer>
      <ImagesContainer ref={containerRef}>
        <ScrollContainer ref={scrollContainerRef}>
          <ScrollContent>
            {data?.ImageCarousel?.map((image, index) => (
              <ImageContainer key={index} align={getAlignment(index)}>
                <ImageWithCaption image={image} index={index} loading='lazy' />
              </ImageContainer>
            ))}
          </ScrollContent>
        </ScrollContainer>
      </ImagesContainer>

    </ColourContainer>
  );
};

export default ImageCarousel;
