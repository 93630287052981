import styled from '@emotion/styled';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Video from '../../panels/Video';

const SectionContainer = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${(props) => props.color ? props.color : '#fff;'};
  flex-direction: ${(props) => (props.reverse ? 'row' : 'row-reverse')};
  @media (min-width: 1026px) {
    max-height: 700px;
  }
  @media (max-width: 1025px) {
    min-height: 800px;
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
  }
`
const SectionCopy = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  align-items: start;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: center;
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
`
const SectionCopyImage = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: center;
    z-index: 2;
    height: 250px
  }
`
const CopyWidthContainer = styled.div`
  padding: 40px;
  @media (max-width: 1025px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  @media (min-width: 1026px) {
    max-width: 100%;
    padding-bottom: 80px;
  }
`
const StoreAddress = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #191919;
  padding: 12px 0;
  line-height: 2;
  & a {
    text-decoration: underline;
  }
`
const StoreTitle = styled.div`
  display: flex;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.11px;
  color: #191919;
  padding: 12px 0;
  justify-content: start;
  @media (min-width: 1026px) {
    height: 100px;
  }
  @media (max-width: 1025px) {
    text-align: center;
    justify-content: center;
    padding-top: 0;
    font-size: 25px;
    font-weight: 500;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  }
`
const DropdownContainer = styled.div`
  width: 100%;
  padding: 40px;
  padding-bottom: 12px;
  & .Dropdown-control {
    padding: 18px;
    border-radius: 25px;
    color: #000;
    cursor: pointer;
    font-size: 18px;
  }

  & .Dropdown-arrow {
    top: 30px;
  }

  & .Dropdown-control {
    border: none;
  }

  & .Dropdown-menu {
    border: none;
    border-radius: 25px;
    margin-top: -63px;
    max-height: none;
  }

  & .Dropdown-option {
    padding: 18px;
    font-size: 18px;
  }

  @media (max-width: 1025px) {
    text-align: start;
  }
`;
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const HeaderVideo = styled(Video)`
  width: 100%;
`
const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const ShopPanel = ({ locationData }) => {
  const availableVariants = locationData.map(val => val.Name)
  const [selectedStore, setSelectedStore] = useState(locationData[0])
  return (
    <SectionContainer
      color={'#F7F6F4'}
      mobilePadding={true}
    >
      <SectionCopyImage>
        {
          selectedStore && selectedStore.Image && (
            selectedStore?.Image?.isStatic ? (
              <StaticHeaderImage src={selectedStore?.Image?.url}
              />
            ) : (
              <>
                {selectedStore?.Image && (
                  selectedStore?.Image?.mime?.includes('video') || selectedStore?.Image?.mime?.includes('gif') ? (
                    <HeaderVideo
                      alt={selectedStore?.Image?.alternativeText || selectedStore?.Image?.name}
                      src={selectedStore?.Image?.file?.publicURL}
                    />
                  ) : (
                    <HeaderImage
                      loading="lazy"
                      alt={selectedStore?.Image?.alternativeText || selectedStore?.Image?.name}
                      image={withArtDirection(getImage(selectedStore?.Image?.file?.childImageSharp?.gatsbyImageData), [
                        {
                          media: '(max-width: 1025px)',
                          image: getImage(selectedStore?.Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                        },
                      ])}
                    />
                  )
                )}
              </>
            )
          )
        }
      </SectionCopyImage>
      <SectionCopy paddingOn={true}>
        <DropdownContainer>
          <Dropdown options={availableVariants} onChange={(e) => (setSelectedStore(locationData.find(val => val.Name === e.label)))} value={selectedStore.Name} placeholder="Select an option" />
        </DropdownContainer>
        <CopyWidthContainer>
          {
            selectedStore && (
              <>
                <StoreTitle>
                  {selectedStore.Name}
                </StoreTitle>
                <StoreAddress color="black" dangerouslySetInnerHTML={{ __html: selectedStore.Details }} />
                {/* {
                  location && (
                    <CoordinatesContainer>
                      <Coordinates>{selectedStore}.</Coordinates>
                      <div style={{ display: 'flex', 'justify-content': 'space-between', minWidth: 120 }}>
                        <LocationData>{location.locationTemp}</LocationData>
                        <LocationData>{location.locationTime}</LocationData>
                      </div>
                    </CoordinatesContainer >
                  )
                }
                <CoordinatesContainer>
                  <Coordinates>London.</Coordinates>
                  <div style={{ display: 'flex', 'justify-content': 'space-between', minWidth: 120 }}>
                    <LocationData>5:00</LocationData>
                    <LocationData>35°</LocationData>
                  </div>
                </CoordinatesContainer> */}
              </>
            )
          }
        </CopyWidthContainer>
      </SectionCopy>
    </SectionContainer >
  )
}

export default ShopPanel
