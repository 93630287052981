import styled from '@emotion/styled'
import { default as React } from 'react'

const WarrantyContainer = styled.div`
  background-color: #000000;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 24px 0;
  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
  }
`
const WarrantySection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`
const WarrantyTitleText = styled.div`
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: -1.11px;
  color: rgb(var(--terracota));
  margin: 0 12px;
  @media (max-width: 1025px) {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.73;
    letter-spacing: -1.39px;
    padding-top: 16px;
  }
`
const WarrantySubTitleText = styled.div`
  font-size: 21px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  color: rgb(var(--very-light-pink));
  margin: 0 16px;
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.56px;
    padding-top: 16px;
  }
`
const StyledImage = styled.img`
  margin: 20px;
  height: 170px;
  width: 170px;

  @media (max-width: 1025px) {
    margin: 0;
    height: 90px;
    width: 90px;
  }
`

const LifetimeWarranty = ({ data }) => {
  return (
    <WarrantyContainer>
      <WarrantySection justify="center">
        <StyledImage
          alt={data.BannerImage?.alternativeText}
          src={data.BannerImage?.file?.publicURL}
        />
        <div>
          <WarrantyTitleText>{data.Heading}</WarrantyTitleText>
          <WarrantySubTitleText> {data.Copy} </WarrantySubTitleText>
          <WarrantySubTitleText>{data.SubCopy}</WarrantySubTitleText>
        </div>
      </WarrantySection>
    </WarrantyContainer>
  )
}

export default LifetimeWarranty
