import React from 'react';
import styled from '@emotion/styled';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999;
`;

const ModalWrapper = styled.div`
  position: relative;
  background: white;
  padding: 48px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 99999999999999999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 24px;
  border: none;
  background: transparent;
  font-size: 32px;
  cursor: pointer;
`;

const Modal = ({ show, children, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <Backdrop onClick={onClose}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalWrapper>
    </Backdrop>
  );
};

export default Modal;
