import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

const ImagesContainer = styled.div`
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`
const ImagesSubContainer = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 100%;
  }
`
const DisplayImages = styled(GatsbyImage)`
  width: 50%;
  @media (max-width: 769px) {
    ${(props) => (props.full ? 'width: 100%;' : '')}
  }
`

const FourSquareImages = ({ data }) => {
  return (
    <ImagesContainer>
      <DisplayImages
        full={true}
        image={getImage(data.SIdeImage.file)}
        alt={data.SIdeImage?.alternativeText}
      />
      <ImagesSubContainer>
        <DisplayImages
          image={getImage(data.SquareImage1[0]?.file)}
          alt={data.SquareImage1[0]?.alternativeText}
        />
        <DisplayImages
          image={getImage(data.SquareImage2[0]?.file)}
          alt={data.SquareImage2[0]?.alternativeText}
        />
        <DisplayImages
          image={getImage(data.SquareImage3[0]?.file)}
          alt={data.SquareImage3[0]?.alternativeText}
        />
        <DisplayImages
          image={getImage(data.SquareImage4[0]?.file)}
          alt={data.SquareImage4[0]?.alternativeText}
        />
      </ImagesSubContainer>
    </ImagesContainer>
  )
}

export default FourSquareImages
