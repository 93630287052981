import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const ImageContainer = styled.div`
  height: 82vw;
  position: relative;
`
const LargeImage = styled.div`
  position: absolute;
  top: 10vw;
  width: 71vw;
  height: 47.3vw;
`
const SmallImage = styled.div`
  position: absolute;
  width: 28vw;
  height: 38vw;
  left: 60vw;
  top: 29vw;
`
const SquareImage = styled.div`
  position: absolute;
  width: 32vw;
  height: 32vw;
  left: 13vw;
  top: 45vw;
`
const FullImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`

const OverlappedImageGallery = ({ data }) => {
  return (
    <div>
      <ImageContainer>
        <LargeImage>
          <FullImage
            objectFit="contain"
            alt={data.LargeImageHorizontal?.alternativeText}
            image={
              data.LargeImageHorizontal?.file?.childImageSharp?.gatsbyImageData
            }
          />
        </LargeImage>
        <SmallImage>
          <FullImage
            objectFit="contain"
            alt={data.SmallImageVertical?.alternativeText}
            image={
              data.SmallImageVertical?.file?.childImageSharp?.gatsbyImageData
            }
          />
        </SmallImage>
        <SquareImage>
          <FullImage
            objectFit="contain"
            alt={data.SmallImageSquare?.alternativeText}
            image={data.SmallImageSquare?.file?.childImageSharp?.gatsbyImageData}
          />
        </SquareImage>
      </ImageContainer>
    </div>
  )
}

export default OverlappedImageGallery
