import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby';

function updateQueryStringWithColor(color, colors) {
  const currentUrl = new URL(window.location.href);
  colors.map((val) => {
    currentUrl.searchParams.delete(val);
  })
  currentUrl.searchParams.set(color, '');
  navigate(currentUrl.pathname + currentUrl.search, { replace: true });
}

const loopArray = (length, index) => {
  if (index > length) {
    return index - length - 1
  } else if (index < 0) {
    return index + length + 1
  } else {
    return index
  }
}

const getDefault = ({ defaultSelected, searchQuery, colors }) => {
  const search = queryString.parse(searchQuery)
  const keys = Object.keys(search)

  let setIndex = defaultSelected

  keys.map((val) => {
    if (colors.includes(val)) {
      setIndex = colors.indexOf(val)
    }
  })
  return setIndex
}

const find = ({searchQuery, colors, oosData}) => {
  let colorHasBeenSelected = false

  const search = queryString.parse(searchQuery)
  const keys = Object.keys(search)

  let selectedIndexFromParams = undefined
  keys.map((val) => {
    if (colors.includes(val)) {
      selectedIndexFromParams = colors.map((val, key) => {
        if (!(key !== undefined &&
          oosData[key] !== undefined &&
          (oosData[key].on_site === true ||
            oosData[key].on_site === undefined))) {
          return 'oos'
        }
        return val
      }).indexOf(val)
    }
  })

  if (oosData && typeof oosData === 'object' && oosData.length > 0) {
    if (
      selectedIndexFromParams !== undefined &&
      oosData[selectedIndexFromParams] !== undefined &&
      (oosData[selectedIndexFromParams].on_site === true ||
        oosData[selectedIndexFromParams].on_site === undefined)
    ) {
      return selectedIndexFromParams
    } else {
      let index = 0
      oosData.map((value, key) => {
        if (
          !colorHasBeenSelected &&
          value.on_site === true &&
          value.avaliable === true &&
          value.preorder === false
        ) {
          index = key
          colorHasBeenSelected = true
        }
      })
      return loopArray(oosData.length - 1, index)
    }
  }
}

function useSelectedColor(searchQuery, defaultSelected, colors, oosData) {
  const [selectedColor, setSelectedColor] = useState(find({searchQuery, colors, oosData}))
  const [prevSelectedColour, setPrevSelectedColor] = useState()
  const [oosPrev, setOosPrev] = useState({})

  useEffect(() => {
    setOosPrev(oosData)
    if (JSON.stringify(oosData) !== JSON.stringify(oosPrev)) {
      setSelectedColor(find({searchQuery, colors, oosData}))
    }
  }, [searchQuery])

  useEffect(() => {
    if (selectedColor !== prevSelectedColour) {
      updateQueryStringWithColor(colors[selectedColor], colors);
      setPrevSelectedColor(selectedColor)
    }
  }, [selectedColor, colors]);

  return [selectedColor, setSelectedColor]
}

export default useSelectedColor
