import React from 'react'
import SplitPanel from '../SplitPanel/split_panel'

const PersonalisationPreview = ({ data }) => {
    return (
        <SplitPanel
            header={data?.Subheading}
            title={data?.Heading}
            body={data?.Body}
            // link={splitPanel1?.link} 
            addPersonalisationOptionOn={true}
            color={data?.Theme?.panel_theme?.Background}
            font={data}
            personalisationSelection={data?.Personalisation}
            Image={{
                alternativeText: '',
                mime: '',
                name: '',
                isStatic: true,
                url: 'https://cdn.july.com/personalisation/cases/carry-new/back/04.png'
            }}
            link={{
                to: data.link,
                label: data?.CTA
            }}
        />
    )
}

export default PersonalisationPreview