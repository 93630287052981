import { graphql, useStaticQuery } from 'gatsby'
import React, {useState} from 'react'

const useStockQuery = () => {
  const stockQuery = useStaticQuery(graphql`
    query {
      allStock {
        nodes {
          skus
          locale
        }
      }
    }
  `)

  const initStock = {
    'en-AU': null,
    'en-CA': null,
    'en-NZ': null,
    'en-GB': null,
    'en': null
  }
  stockQuery.allStock.nodes.map(stockLine => {
    initStock[stockLine.locale] = JSON.parse(stockLine.skus)
  })

  const [stock, setStock] = useState(initStock)

  return [stock, setStock]
}

export default useStockQuery