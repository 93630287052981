import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, EmblaCarouselDotButton } from '../../panels/Button/EmblaCarouselDotButton'
import { PrevButton, NextButton, EmBlaCarouselArrowButtons } from "../../panels/Button/EmblaCarouselArrowButtons";
import Autoplay from 'embla-carousel-autoplay'

const InstagramImage = styled(GatsbyImage)`
  width: 320px;
  height: 320px;
  object-fit: cover;
  background: #F5F3EF;
`
const MainCointainer = styled.div`
  margin: 32px;
  position: relative;

  @media (max-width: 500px) {
    .slick-prev{
      left: -7% !important;
    }
    .slick-next{
      right: -7% !important;
    }
  }

  @media (max-width: 820px) {
    .slick-prev{
      left: -4% !important;
    }
    .slick-next{
      right: -4% !important;
    }
  }
`
const VariantTitle = styled.div`
  font-weight: 500;
  color: rgb(var(--dark-blue-grey));
  text-align: center;
  width: 314px;
  margin-top: 10px;
`
const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
  gap: 10%;
  padding: 0 10%;

  @media (max-width: 1650px) {
    padding: 0 7%;
    gap: 7%;
  }

  @media (max-width: 1570px) {
    padding: 0 5%;
    gap: 5%;
  }

  @media (max-width: 1450px) {
    padding: 0;
    gap: 0;
  }

  @media (max-width: 1350px) {
    padding: 0 -4%;
    gap: 0;
  }

  @media (max-width: 1025px) {
    gap: 60rem;
    padding: 0 60rem;
    .embla__slide {
      width: 100%;
      min-width: 100%;
    }
    .embla__slide > div {
      width: 100%;
    }
  }

`

const EmblaControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 1.8rem;

  @media (min-width: 1028px), (max-width: 479px) {
    display: none;
  }
`;

const EmblaDots = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
`

const MultipleSKUsComponent = ({ data }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'start',
    skipSnaps: false,
    dragFree: true,
    loop: true,
    containScroll: 'keepSnaps',
  }, [
    Autoplay({ playOnInit: true, delay: 3000 })
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = EmblaCarouselDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = EmBlaCarouselArrowButtons(emblaApi)

  return (
    <MainCointainer>
      <div id="swiper-panel"></div>
      <div>
        <Embla ref={emblaRef}>
          <EmblaContainer >
            {data.VariantWithImage.map((val, key) => {
              return (
                <div key={key} className="embla__slide">
                  <div>
                    <InstagramImage
                      alt={val.Image?.alternativeText}
                      image={withArtDirection(
                        getImage(val.Image?.file?.childImageSharp?.gatsbyImageData),
                        [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(val.Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                          },
                        ]
                      )}
                      style={{ objectFit: 'contain' }}
                    />
                    <VariantTitle>
                      {val.VariantTitle}
                    </VariantTitle>
                  </div>
                </div>
              )
            })}
          </EmblaContainer>
          <EmblaControls className="embla__controls">
            <EmblaDots className="embla__dots">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={`${index === selectedIndex ? ' embla__dot--selected' : ''}`}
                />
              ))}
            </EmblaDots>
          </EmblaControls>
        </Embla>
      </div>
      <PrevButton style={{ left: '-1.5%', top: '45%' }} onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      <NextButton style={{ right: '-1.5%', top: '45%' }} onClick={onNextButtonClick} disabled={nextBtnDisabled} />
    </MainCointainer>
  )
}
export default MultipleSKUsComponent
