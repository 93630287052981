import React from 'react'
import { Slice } from 'gatsby'

const CaroselPanel = ({ data }) => {
  return (
    <Slice
        panelData={data}
        alias={`shop-carosel`}
    />
  )
}

export default CaroselPanel
