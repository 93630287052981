import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import Instagram from '../../../images/instagram.svg'
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, EmblaCarouselDotButton } from '../../panels/Button/EmblaCarouselDotButton'
import { PrevButton, NextButton, EmBlaCarouselArrowButtons } from "../../panels/Button/EmblaCarouselArrowButtons";
import Autoplay from 'embla-carousel-autoplay'

const ReviewHeader = styled.div`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -1.11px;
  text-align: center;
  color: rgb(var(--dark-blue-grey));
  padding: 64px 42px;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 16px;
  @media (max-width: 1025px) {
    padding: 32px 0px;
    font-size: 32px;
    line-height: 1.38;
  }
`
const InstagramImage = styled(GatsbyImage)`
  width: 400px;
  height: 400px;
  object-fit: cover;
`
const TransparentButton = styled.button`
  position: relative;
  font-family: Objectivity;
  border-radius: 41.5px;
  border: solid 1px rgb(var(--${(props) => props.color}));
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: rgb(var(--${(props) => props.color}));
  background: transparent;
  height: 40px;
  padding: 0 24px;
  outline: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 12px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : '')}

  transition: all 0.2s;

  &:hover {
    background-color: rgba(var(--${(props) => props.color}), 0.2);
    color: white;
    border-color: transparent;
  }
  &:hover {
    background-color: rgba(var(--${(props) => props.color}), 1);
    color: white;
    border-color: transparent;
    & img {
      filter: invert(100%);
    }
  }
`
const InstagramImg = styled.img`
  transition: filter 0.2s;
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
  min-width: 0;
  flex: 0 0 100%;
  gap: 20%;
  padding: 0 20%;
  .embla__slide > div {
    width: 100%;
  }

  @media (max-width: 1645px) {
    gap: 15%;
    padding: 0 15%;
  }

  @media (max-width: 1575px) {
    gap: 10%;
    padding: 0 10%;
  }

  @media (max-width: 1475px) {
    gap: 7%;
    padding: 0 7%;
  }
  @media (max-width: 1375px) {
    gap: 4%;
    padding: 0 4%;
  }
  @media (max-width: 1275px) {
    gap: 0;
    padding: 0;
  }

  @media (max-width: 1175px) {
    margin: 0 -5%;
  }
  @media (max-width: 1120px) {
    margin: 0 -7%;
  }
  @media (max-width: 1080px) {
    margin: 0 -10%;
  }

  @media (max-width: 1035px) {
    margin: 0;
    gap: 70rem;
    padding: 0 70rem;
    .embla__slide {
      width: 100%;
      min-width: 100%;
    }
    .embla__slide > div {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    margin: 0;
    gap: 30rem;
    padding: 0 30rem;
  }
`

const EmblaControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 1.8rem;

  @media (min-width: 1028px), (max-width: 479px) {
    display: none;
  }
`;

const EmblaDots = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
`

const InstagramFeed = ({ data }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'start',
    skipSnaps: false,
    dragFree: true,
    loop: true,
    containScroll: 'keepSnaps',
  }, [
    Autoplay({ playOnInit: true, delay: 3000 })
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = EmblaCarouselDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = EmBlaCarouselArrowButtons(emblaApi)

  return (
    <div style={{ padding: 32, position: 'relative' }}>
      <ReviewHeader>{data.Heading}</ReviewHeader>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a
          target="_blank"
          href="https://www.instagram.com/july/"
          rel="noreferrer"
        >
          <TransparentButton
            noMobile={true}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 3,
            }}
            color="black"
          >
            <InstagramImg
              src={Instagram}
              style={{
                height: '1.3em',
                padding: '0 4px',
                marginTop: -3,
              }}
            />
            {data.CTA}
          </TransparentButton>
        </a>
      </div>
      <div style={{ margin: '64px 0', marginTop: 32 }}>

        <Embla ref={emblaRef}>
          <EmblaContainer >
            {data.InstagramItems.map((val, key) => {
              return (
                <div key={key} className='embla__slide'>
                  <div style={{ paddingBottom: 48 }}>
                    <InstagramImage
                      alt={val.Image?.alternativeText}
                      image={withArtDirection(
                        getImage(val.Image?.file?.childImageSharp?.gatsbyImageData),
                        [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(val.Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                          },
                        ]
                      )}
                      style={{ objectFit: 'contain' }}
                    />
                    <div
                      style={{
                        fontWeight: 500,
                        color: 'rgb(var(--dark-blue-grey))',
                      }}
                    >
                      {val.UserName}
                    </div>
                  </div>
                </div>
              )
            })}
            {data.InstagramItems.map((val, key) => {
              return (
                <div key={key}>
                  <div style={{ paddingBottom: 48 }}>
                    <InstagramImage
                      alt={val.Image?.alternativeText}
                      image={withArtDirection(
                        getImage(val.Image?.file?.childImageSharp?.gatsbyImageData),
                        [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(val.Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                          },
                        ]
                      )}
                      style={{ objectFit: 'contain' }}
                    />
                    <div
                      style={{
                        fontWeight: 500,
                        color: 'rgb(var(--dark-blue-grey))',
                      }}
                    >
                      {val.UserName}
                    </div>
                  </div>
                </div>
              )
            })}
          </EmblaContainer>
          <EmblaControls className="embla__controls">
            <EmblaDots className="embla__dots">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={`${index === selectedIndex ? ' embla__dot--selected' : ''}`}
                />
              ))}
            </EmblaDots>
          </EmblaControls>
        </Embla>


      </div>
      <PrevButton style={{ left: '0.5%', top: '59%' }} onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      <NextButton style={{ right: '0.5%', top: '59%' }} onClick={onNextButtonClick} disabled={nextBtnDisabled} />
    </div>
  )
}
export default InstagramFeed
