const AU = [
  "TRK-COPB02-GRY",
  "TRK-COPB10-CRM",
  "TRK-COPB11-FBLU",
  "TRK-COPB12-PLUM",
  "TRK-COPB13-MSAN",
  "TRK-COPB14-MGRE",
  "TRK-COPB15-MRED",
  "TRK-CHK02-GRY",
  "TRK-CHK07-BRO",
  "TRK-CHK10-CRM",
  "TRK-CHK11-FBLU",
  "TRK-CHK12-PLUM",
  "TRK-CHK13-MSAN",
  "TRK-CHK14-MGRE",
  "TRK-CHK15-MRED",
  "SET-TRK-CHKCOPB02-GRY",
  "SET-TRK-CHKCOPB10-CRM",
  "SET-TRK-CHKCOPB11-FBLU",
  "SET-TRK-CHKCOPB12-PLUM",
  "SET-TRK-CHKCOPB13-MSAN",
  "SET-TRK-CHKCOPB14-MGRE",
  "SET-TRK-CHKCOPB15-MRED",
  "CME24-SAN",
  "CME30-MOS",
  "CME33-LTAU",
  "TRK-COPB16-MRED-RE",
  "AOC-PROV2-COPB28-CRM",
  "STR01-NAV",
  "STR02-CHA",
  "STR03-FOR",
  "STR06-SHA",
  "STR08-BLU",
  "CHK06-MON",
  "CHK08-SKY",
  "CHK09-SHW",
  "CHK22-BPK",
  "CHK23-ORA",
  "CHK24-SBLU",
  "CAR-BPK02-BLA",
  "CAR-BPK01-NAV",
  "CAR-BPK03-FOR",
  "4PC01-SNO",
  "4PC08-DBRO",
  "4PC09-BIS",
  "DBK-BPK01-BLA",
  "DBK-BPK02-NAV",
  "DBK-BPK03-TAU",
  "SET-CHKCOPB24-SBLU",
  "VOL-BPK33-GRY",
  "LGT-CO17-DBRO",
  "COV-TRCO04-GRN",
  "COV-TRCO05-RED",
  "COV-TRCH07-KHA",
  "COV-TRCH08-GRN",
  "COV-TRCH09-RED",
  "COV-CLCO14-BLA",
  "COV-CLCO15-KHA",
  "COV-CLCH18-BLA",
  "COV-CLCH19-KHA",
  "COV-CLCH20-GRN",
  "COV-CLCH21-RED",
  "COV-CLPL22-BLA",
  "COV-CLPL23-KHA",
  "COV-CLPL25-RED",
  "COV-TRCO29-CRM",
  "COV-TRCO30-FBLU",
  "COV-TRCO31-PLUM",
  "COV-TRCH32-CRM",
  "TAG01-BLA",
  "TAG02-GRY",
  "BOT-SML01-BLA",
  "BOT-SML02-NAV",
  "BOT-LAR01-BLA",
  "BOT-LAR02-NAV",
  "BOT-LAR05-MOS",
  "BOT-LAR07-YEL",
  "CAR-WKD02-BLA",
  "CAR-WKD03-FOR",
  "CAR-WKD04-CHK",
  "TPC01-BLA-BLU",
  "TPC04-BLA-GRY",
  "CAR-WKDP03-CHK",
  "TEC03-BLU",
  "TOY01-BLA",
  "EVETOT-LAR23-NAV",
  "EVETOT-LAR27-COP",
  "EVETOT2-LAR26-NAT",
  "EVETOT2-LAR29-YEL",
  "EVETOT2-LAR30-BRO",
  "EVETOT2-LAR31-GRY",
  "EVETOT2-LAR32-RED",
  "EVETOT2-LAR33-LBLU",
  "PRO-COPB11-OLI",
  "PRO-COPB13-FOR",
  "PRO-COPB12-NAV",
  "PB01-BLA",
  "COPB08-SKY",
  "COPB14-BLU",
  "COPB22-BPK",
  "COPB26-SBLU",
  "COPB27-BONE",
  "PLS08-SKY",
  "PLS13-YEL",
  "PLS14-BLU",
  "PLS22-BPK",
  "PLS23-ORA",
  "LGT-COEX26-SHA",
  "LGT-COEX28-CGRN",
  "LGT-COEX29-MORA",
  "LGT-COEX30-DBRO",
  "8PC01-SNO",
  "8PC02-CHA",
  "8PC03-FOR",
  "8PC07-MOS",
  "8PC08-DBRO",
  "8PC09-BIS",
  "PRO-SLEE01-BLA",
  "WHL-CLA2X-BLA-L",
  "WHL-CLA2X-BLA-S",
  "COES21-NAV",
  "COES23-FOR",
  "COES24-SAN",
  "COES26-SHA",
  "COES27-YEL",
  "COES28-OLI",
  "COES29-BLU",
  "APC05-BLA-BLU",
  "APC07-BLA-YEL",
  "VOL-WKD31-BLA",
  "LGT-CHK41-NAV",
  "LGT-CHK44-SAN",
  "LGT-CHK45-CLA",
  "LGT-CHK50-DBRO",
  "LGT-PLS61-NAV",
  "LGT-PLS64-SAN",
  "LGT-PLS68-CGRN",
  "LGT-PLS70-DBRO",
  "EXP-PLS41-CHA",
  "EXP-PLS49-LTAU",
  "EVE-BPK41-NAT",
  "CPS01-SNO",
  "STR-EVE11-BLA",
  "SET-LGT-CHKCO80-DBRO",
  "SET-LGT-PLSCO80-DBRO",
  "SET-LGT-FMLY80-DBRO",
  "SET-LGTEX-CHKCO90-DBRO",
  "SET-LGTEX-PLSCO80-DBRO",
  "SET-LGTEX-FMLY80-DBRO",
  "TRK-PRO-COPB01-SAN",
  "TRK-PRO-COPB03-GRE",
  "TRK-PRO-COPB04-RED",
  "TRK-PRO-COPB05-MBLA",
  "SLG-TAG40-BLA",
  "SLG-TAG42-PLM",
  "SLG-TAG43-BRO",
  "SLG-TAG10-BLA",
  "SLG-TAG12-PLM",
  "SLG-TAG13-BRO",
  "SLG-TAG14-BLA-CORP",
  "SLG-TAG15-RED",
  "SLG-TAG22-PLM",
  "SLG-TAG23-BRO",
  "SLG-TAG25-RED",
  "SLG-TAG20-BLA-CORP",
  "SLG-TAG30-BLA",
  "SLG-TAG31-OYS",
  "SLG-TAG32-PLM",
  "SLG-TAG33-BRO",
  "SLG-TAG35-RED",
  "SLG-CARD03-PLM",
  "SLG-CARD04-BRO",
  "SLG-CARD05-RED",
  "SLG-PASS01-BLA",
  "SLG-PASS03-PLM",
  "SLG-PASS04-BRO",
  "SLG-WLT03-PLM-S",
  "SLG-WLT04-BRO-S",
  "SLG-WLT12-PLM-L",
  "SLG-WLT13-BRO-L",
  "EVETOT-MINI65-GRY",
  "SBAG01-BERLIN-L",
  "SBAG01-BERLIN-S",
  "SBAG03-CANCUN-L",
  "SBAG03-CANCUN-S",
  "SBAG05-SEOUL-S",
  "SBAG05-SEOUL-L",
  "BAG-CLA-COPB",
  "TSA-CLA-BLA",
  "BAG-CLA-CHK",
  "BAG-CLA-PLS",
  "BAG-TRK-COPB",
  "BAG-TRK-CHK",
  "TSA-LGT-BLA",
  "TSA-TRK-SAN",
  "TSA-TRK-GRY",
  "TSA-TRK-GRE",
  "FT-CLA2X-BLA",
  "FT-TRK2X-SAN",
  "FT-TRK2X-GRY",
  "FT-TRK2X-GRE",
  "FID-SNAP-BLA",
  "TRK-HING-PIN",
  "TSA-TRK-MBLA",
  "STR-BUCK-MALE",
  "TSA-CLA-BLA-LRG",
  "FT-TRK2X-RED",
  "FT-TRK2X-CBLU",
  "FT-TRK2X-BRO",
  "FT-TRK2X-MOS",
  "FT-TRK2X-MBL",
  "TSA-TRK-RED",
  "TSA-TRK-BRO",
  "TSA-TRK-MOS",
  "TSA-TRK-CBLU",
  "TSA-TRK-MBLA",
  "TSA-TRK-MBLA",
  "TSA-TRK-LGRY",
  "ZIP-PULLER-CLA",
  "CABLE-PB",
  "WHL-COPB2X-BLA",
  "WHL-CHKPLS2X-BLA",
  "WHL-COPB4X-BLA",
  "WHL-CHKPLS4X-BLA",
  "WHL-LGT2X-BLA",
  "WHL-TRK2X-SAN",
  "WHL-TRK2X-GRY",
  "WHL-TRK2X-GRE",
  "HDL-CLA-BLA",
  "DUST-COPB-CLA",
  "DUST-CHK-CLA",
  "DUST-PLS-CLA",
  "DUST-COPB-TRK",
  "PB-CAGE-CLA",
  "BOT-LID-BLA",
  "HDL-TOP-BLA",
  "CAR-TOT-STR",
  "EVE-STR-NAT",
  "WHL-LGT-PLS-CHK",
  "WHL-LGT-CHK-PLS",
  "PAD-CLA-COPB",
  "PAD-CLA-CHK",
  "PAD-CLA-PLS",
  "PAD-TRK-COPB",
  "PAD-TRK-CHK",
  "TELHDL-CLA-COPB-BLA",
  "TELHDL-CLA-CHK-BLA",
  "TELHDL-CLA-PLS-BLA",
  "TELHDL-TRK-COPB-SAN",
  "TELHDL-TRK-COPB-GRY",
  "TELHDL-TRK-COPB-GRE",
  "TELHDL-TRK-COPB-MOS",
  "TELHDL-TRK-COPB-RED",
  "TELHDL-TRK-COPB-MBLA",
  "TELHDL-TRK-COPB-BRO",
  "TELHDL-TRK-COPB-LGRY",
  "TELHDL-TRK-CHK-SAN",
  "TELHDL-TRK-CHK-GRY",
  "TELHDL-TRK-CHK-GRE",
  "TELHDL-TRK-CHK-RED",
  "TELHDL-TRK-CHK-BRO",
  "TELHDL-TRK-CHK-LGRY",
  "TRK-PLS25-MBLA",
  "TRK-PLS21-SAN",
  "TRK-PLS24-RED",
  "TRK-PLS27-BRO",
  "TRK-PLS28-MOS",
  "TRK-PLS29-LGRY",
  "JUPA-MIXS",
  "JUPA-MIXM",
  "SLG-WLT20-BLA-C",
  "SLG-WLT21-OYS-C",
  "SLG-WLT22-RED-C",
  "JETTE-TOT-LRG01-BLA",
  "JETTE-TOT-LRG02-BRO",
  "JETTE-TOT-LRG03-BONE",
  "JETTE-TOT-SML05-BLA",
  "JETTE-TOT-SML06-BRO",
  "JETTE-TOT-SML07-BONE",
  "JETTE-TOT-SML08-GRN",
  "JETTE-CRSBDY09-BLA",
  "JETTE-CRSBDY10-BRO",
  "JETTE-CRSBDY11-BONE",
  "JETTE-CRSBDY12-GRN",
  "STK-CITY-01",
  "STK-ALPH-01",
  "EVETOT-LTD-MINI69-TAN",
  "EVETOT-LTD-MINI70-BLA",
  "EVETOT2-SML37-BLA",
  "EVETOT2-SML39-MOS",
  "EVETOT2-SML43-RED",
  "EVETOT2-SML44-LBLU",
  "EVETOT2-SML45-BIS",
  "SLG-TAG36-REF",
  "TRK-COPB17-BONE-OROTON"
];

const US = [
  // "TRK-COPB02-GRY",
  // "TRK-COPB10-CRM",
  // "TRK-COPB11-FBLU",
  // "TRK-COPB12-PLUM",
  // "TRK-COPB13-MSAN",
  // "TRK-COPB14-MGRE",
  // "TRK-COPB15-MRED",
  // "TRK-CHK02-GRY",
  // "TRK-CHK07-BRO",
  // "TRK-CHK10-CRM",
  // "TRK-CHK11-FBLU",
  // "TRK-CHK12-PLUM",
  // "TRK-CHK13-MSAN",
  // "TRK-CHK14-MGRE",
  // "TRK-CHK15-MRED",
  // "SET-TRK-CHKCOPB02-GRY",
  // "SET-TRK-CHKCOPB10-CRM",
  // "SET-TRK-CHKCOPB11-FBLU",
  // "SET-TRK-CHKCOPB12-PLUM",
  // "SET-TRK-CHKCOPB13-MSAN",
  // "SET-TRK-CHKCOPB14-MGRE",
  // "SET-TRK-CHKCOPB15-MRED",
  // "CME24-SAN",
  // "CME30-MOS",
  // "CME33-LTAU",
  // "TRK-COPB16-MRED-RE",
  // "AOC-PROV2-COPB28-CRM",
  // "STR01-NAV",
  // "STR02-CHA",
  // "STR03-FOR",
  // "STR06-SHA",
  // "STR08-BLU",
  // "CHK06-MON",
  // "CHK08-SKY",
  // "CHK09-SHW",
  // "CHK22-BPK",
  // "CHK23-ORA",
  // "CHK24-SBLU",
  // "CAR-BPK02-BLA",
  // "CAR-BPK01-NAV",
  // "CAR-BPK03-FOR",
  // "4PC01-SNO",
  // "4PC08-DBRO",
  // "4PC09-BIS",
  // "DBK-BPK01-BLA",
  // "DBK-BPK02-NAV",
  // "DBK-BPK03-TAU",
  // "SET-CHKCOPB24-SBLU",
  // "VOL-BPK33-GRY",
  // "LGT-CO17-DBRO",
  // "COV-TRCO04-GRN",
  // "COV-TRCO05-RED",
  // "COV-TRCH07-KHA",
  // "COV-TRCH08-GRN",
  // "COV-TRCH09-RED",
  // "COV-CLCO14-BLA",
  // "COV-CLCO15-KHA",
  // "COV-CLCH18-BLA",
  // "COV-CLCH19-KHA",
  // "COV-CLCH20-GRN",
  // "COV-CLCH21-RED",
  // "COV-CLPL22-BLA",
  // "COV-CLPL23-KHA",
  // "COV-CLPL25-RED",
  // "COV-TRCO29-CRM",
  // "COV-TRCO30-FBLU",
  // "COV-TRCO31-PLUM",
  // "COV-TRCH32-CRM",
  // "TAG01-BLA",
  // "TAG02-GRY",
  // "BOT-SML01-BLA",
  // "BOT-SML02-NAV",
  // "BOT-LAR01-BLA",
  // "BOT-LAR02-NAV",
  // "BOT-LAR05-MOS",
  // "BOT-LAR07-YEL",
  // "CAR-WKD02-BLA",
  // "CAR-WKD03-FOR",
  // "CAR-WKD04-CHK",
  // "TPC01-BLA-BLU",
  // "TPC04-BLA-GRY",
  // "CAR-WKDP03-CHK",
  // "TEC03-BLU",
  // "TOY01-BLA",
  // "EVETOT-LAR23-NAV",
  // "EVETOT-LAR27-COP",
  // "EVETOT2-LAR26-NAT",
  // "EVETOT2-LAR29-YEL",
  // "EVETOT2-LAR30-BRO",
  // "EVETOT2-LAR31-GRY",
  // "EVETOT2-LAR32-RED",
  // "EVETOT2-LAR33-LBLU",
  // "PRO-COPB11-OLI",
  // "PRO-COPB13-FOR",
  // "PRO-COPB12-NAV",
  // "PB01-BLA",
  // "COPB08-SKY",
  // "COPB14-BLU",
  // "COPB22-BPK",
  // "COPB26-SBLU",
  // "COPB27-BONE",
  // "PLS08-SKY",
  // "PLS13-YEL",
  // "PLS14-BLU",
  // "PLS22-BPK",
  // "PLS23-ORA",
  // "LGT-COEX26-SHA",
  // "LGT-COEX28-CGRN",
  // "LGT-COEX29-MORA",
  // "LGT-COEX30-DBRO",
  // "8PC01-SNO",
  // "8PC02-CHA",
  // "8PC03-FOR",
  // "8PC07-MOS",
  // "8PC08-DBRO",
  // "8PC09-BIS",
  // "PRO-SLEE01-BLA",
  // "WHL-CLA2X-BLA-L",
  // "WHL-CLA2X-BLA-S",
  // "COES21-NAV",
  // "COES23-FOR",
  // "COES24-SAN",
  // "COES26-SHA",
  // "COES27-YEL",
  // "COES28-OLI",
  // "COES29-BLU",
  // "APC05-BLA-BLU",
  // "APC07-BLA-YEL",
  // "VOL-WKD31-BLA",
  // "LGT-CHK41-NAV",
  // "LGT-CHK44-SAN",
  // "LGT-CHK45-CLA",
  // "LGT-CHK50-DBRO",
  // "LGT-PLS61-NAV",
  // "LGT-PLS64-SAN",
  // "LGT-PLS68-CGRN",
  // "LGT-PLS70-DBRO",
  // "EXP-PLS41-CHA",
  // "EXP-PLS49-LTAU",
  // "EVE-BPK41-NAT",
  // "CPS01-SNO",
  // "STR-EVE11-BLA",
  // "SET-LGT-CHKCO80-DBRO",
  // "SET-LGT-PLSCO80-DBRO",
  // "SET-LGT-FMLY80-DBRO",
  // "SET-LGTEX-CHKCO90-DBRO",
  // "SET-LGTEX-PLSCO80-DBRO",
  // "SET-LGTEX-FMLY80-DBRO",
  // "TRK-PRO-COPB01-SAN",
  // "TRK-PRO-COPB03-GRE",
  // "TRK-PRO-COPB04-RED",
  // "TRK-PRO-COPB05-MBLA",
  // "SLG-TAG40-BLA",
  // "SLG-TAG42-PLM",
  // "SLG-TAG43-BRO",
  // "SLG-TAG10-BLA",
  // "SLG-TAG12-PLM",
  // "SLG-TAG13-BRO",
  // "SLG-TAG14-BLA-CORP",
  // "SLG-TAG15-RED",
  // "SLG-TAG22-PLM",
  // "SLG-TAG23-BRO",
  // "SLG-TAG25-RED",
  // "SLG-TAG20-BLA-CORP",
  // "SLG-TAG30-BLA",
  // "SLG-TAG31-OYS",
  // "SLG-TAG32-PLM",
  // "SLG-TAG33-BRO",
  // "SLG-TAG35-RED",
  // "SLG-CARD03-PLM",
  // "SLG-CARD04-BRO",
  // "SLG-CARD05-RED",
  // "SLG-PASS01-BLA",
  // "SLG-PASS03-PLM",
  // "SLG-PASS04-BRO",
  // "SLG-WLT03-PLM-S",
  // "SLG-WLT04-BRO-S",
  // "SLG-WLT12-PLM-L",
  // "SLG-WLT13-BRO-L",
  // "EVETOT-MINI65-GRY",
  // "SBAG01-BERLIN-L",
  // "SBAG01-BERLIN-S",
  // "SBAG03-CANCUN-L",
  // "SBAG03-CANCUN-S",
  // "SBAG05-SEOUL-S",
  // "SBAG05-SEOUL-L",
  // "BAG-CLA-COPB",
  // "TSA-CLA-BLA",
  // "BAG-CLA-CHK",
  // "BAG-CLA-PLS",
  // "BAG-TRK-COPB",
  // "BAG-TRK-CHK",
  // "TSA-LGT-BLA",
  // "TSA-TRK-SAN",
  // "TSA-TRK-GRY",
  // "TSA-TRK-GRE",
  // "FT-CLA2X-BLA",
  // "FT-TRK2X-SAN",
  // "FT-TRK2X-GRY",
  // "FT-TRK2X-GRE",
  // "FID-SNAP-BLA",
  // "TRK-HING-PIN",
  // "TSA-TRK-MBLA",
  // "STR-BUCK-MALE",
  // "TSA-CLA-BLA-LRG",
  // "FT-TRK2X-RED",
  // "FT-TRK2X-CBLU",
  // "FT-TRK2X-BRO",
  // "FT-TRK2X-MOS",
  // "FT-TRK2X-MBL",
  // "TSA-TRK-RED",
  // "TSA-TRK-BRO",
  // "TSA-TRK-MOS",
  // "TSA-TRK-CBLU",
  // "TSA-TRK-MBLA",
  // "TSA-TRK-MBLA",
  // "TSA-TRK-LGRY",
  // "ZIP-PULLER-CLA",
  // "CABLE-PB",
  // "WHL-COPB2X-BLA",
  // "WHL-CHKPLS2X-BLA",
  // "WHL-COPB4X-BLA",
  // "WHL-CHKPLS4X-BLA",
  // "WHL-LGT2X-BLA",
  // "WHL-TRK2X-SAN",
  // "WHL-TRK2X-GRY",
  // "WHL-TRK2X-GRE",
  // "HDL-CLA-BLA",
  // "DUST-COPB-CLA",
  // "DUST-CHK-CLA",
  // "DUST-PLS-CLA",
  // "DUST-COPB-TRK",
  // "PB-CAGE-CLA",
  // "BOT-LID-BLA",
  // "HDL-TOP-BLA",
  // "CAR-TOT-STR",
  // "EVE-STR-NAT",
  // "WHL-LGT-PLS-CHK",
  // "WHL-LGT-CHK-PLS",
  // "PAD-CLA-COPB",
  // "PAD-CLA-CHK",
  // "PAD-CLA-PLS",
  // "PAD-TRK-COPB",
  // "PAD-TRK-CHK",
  // "TELHDL-CLA-COPB-BLA",
  // "TELHDL-CLA-CHK-BLA",
  // "TELHDL-CLA-PLS-BLA",
  // "TELHDL-TRK-COPB-SAN",
  // "TELHDL-TRK-COPB-GRY",
  // "TELHDL-TRK-COPB-GRE",
  // "TELHDL-TRK-COPB-MOS",
  // "TELHDL-TRK-COPB-RED",
  // "TELHDL-TRK-COPB-MBLA",
  // "TELHDL-TRK-COPB-BRO",
  // "TELHDL-TRK-COPB-LGRY",
  // "TELHDL-TRK-CHK-SAN",
  // "TELHDL-TRK-CHK-GRY",
  // "TELHDL-TRK-CHK-GRE",
  // "TELHDL-TRK-CHK-RED",
  // "TELHDL-TRK-CHK-BRO",
  // "TELHDL-TRK-CHK-LGRY",
  // "TRK-PLS25-MBLA",
  // "TRK-PLS21-SAN",
  // "TRK-PLS24-RED",
  // "TRK-PLS27-BRO",
  // "TRK-PLS28-MOS",
  // "TRK-PLS29-LGRY",
  // "JUPA-MIXS",
  // "JUPA-MIXM",
  // "SLG-WLT20-BLA-C",
  // "SLG-WLT21-OYS-C",
  // "SLG-WLT22-RED-C",
  // "JETTE-TOT-LRG01-BLA",
  // "JETTE-TOT-LRG02-BRO",
  // "JETTE-TOT-LRG03-BONE",
  // "JETTE-TOT-SML05-BLA",
  // "JETTE-TOT-SML06-BRO",
  // "JETTE-TOT-SML07-BONE",
  // "JETTE-TOT-SML08-GRN",
  // "JETTE-CRSBDY09-BLA",
  // "JETTE-CRSBDY10-BRO",
  // "JETTE-CRSBDY11-BONE",
  // "JETTE-CRSBDY12-GRN",
  // "STK-CITY-01",
  // "STK-ALPH-01",
  // "EVETOT-LTD-MINI69-TAN",
  // "EVETOT-LTD-MINI70-BLA",
  // "EVETOT2-SML37-BLA",
  // "EVETOT2-SML39-MOS",
  // "EVETOT2-SML43-RED",
  // "EVETOT2-SML44-LBLU",
  // "EVETOT2-SML45-BIS",
  // "SLG-TAG36-REF",
  // "TRK-COPB17-BONE-OROTON"
];

const NZ = [
  "TRK-COPB02-GRY",
  "TRK-COPB10-CRM",
  "TRK-COPB11-FBLU",
  "TRK-COPB12-PLUM",
  "TRK-COPB13-MSAN",
  "TRK-COPB14-MGRE",
  "TRK-COPB15-MRED",
  "TRK-CHK02-GRY",
  "TRK-CHK07-BRO",
  "TRK-CHK10-CRM",
  "TRK-CHK11-FBLU",
  "TRK-CHK12-PLUM",
  "TRK-CHK13-MSAN",
  "TRK-CHK14-MGRE",
  "TRK-CHK15-MRED",
  "SET-TRK-CHKCOPB02-GRY",
  "SET-TRK-CHKCOPB10-CRM",
  "SET-TRK-CHKCOPB11-FBLU",
  "SET-TRK-CHKCOPB12-PLUM",
  "SET-TRK-CHKCOPB13-MSAN",
  "SET-TRK-CHKCOPB14-MGRE",
  "SET-TRK-CHKCOPB15-MRED",
  "CME24-SAN",
  "CME30-MOS",
  "CME33-LTAU",
  "TRK-COPB16-MRED-RE",
  "AOC-PROV2-COPB28-CRM",
  "STR01-NAV",
  "STR02-CHA",
  "STR03-FOR",
  "STR06-SHA",
  "STR08-BLU",
  "CHK06-MON",
  "CHK08-SKY",
  "CHK09-SHW",
  "CHK22-BPK",
  "CHK23-ORA",
  "CHK24-SBLU",
  "CAR-BPK02-BLA",
  "CAR-BPK01-NAV",
  "CAR-BPK03-FOR",
  "4PC01-SNO",
  "4PC08-DBRO",
  "4PC09-BIS",
  "DBK-BPK01-BLA",
  "DBK-BPK02-NAV",
  "DBK-BPK03-TAU",
  "SET-CHKCOPB24-SBLU",
  "VOL-BPK33-GRY",
  "LGT-CO17-DBRO",
  "COV-TRCO04-GRN",
  "COV-TRCO05-RED",
  "COV-TRCH07-KHA",
  "COV-TRCH08-GRN",
  "COV-TRCH09-RED",
  "COV-CLCO14-BLA",
  "COV-CLCO15-KHA",
  "COV-CLCH18-BLA",
  "COV-CLCH19-KHA",
  "COV-CLCH20-GRN",
  "COV-CLCH21-RED",
  "COV-CLPL22-BLA",
  "COV-CLPL23-KHA",
  "COV-CLPL25-RED",
  "COV-TRCO29-CRM",
  "COV-TRCO30-FBLU",
  "COV-TRCO31-PLUM",
  "COV-TRCH32-CRM",
  "TAG01-BLA",
  "TAG02-GRY",
  "BOT-SML01-BLA",
  "BOT-SML02-NAV",
  "BOT-LAR01-BLA",
  "BOT-LAR02-NAV",
  "BOT-LAR05-MOS",
  "BOT-LAR07-YEL",
  "CAR-WKD02-BLA",
  "CAR-WKD03-FOR",
  "CAR-WKD04-CHK",
  "TPC01-BLA-BLU",
  "TPC04-BLA-GRY",
  "CAR-WKDP03-CHK",
  "TEC03-BLU",
  "TOY01-BLA",
  "EVETOT-LAR23-NAV",
  "EVETOT-LAR27-COP",
  "EVETOT2-LAR26-NAT",
  "EVETOT2-LAR29-YEL",
  "EVETOT2-LAR30-BRO",
  "EVETOT2-LAR31-GRY",
  "EVETOT2-LAR32-RED",
  "EVETOT2-LAR33-LBLU",
  "PRO-COPB11-OLI",
  "PRO-COPB13-FOR",
  "PRO-COPB12-NAV",
  "PB01-BLA",
  "COPB08-SKY",
  "COPB14-BLU",
  "COPB22-BPK",
  "COPB26-SBLU",
  "COPB27-BONE",
  "PLS08-SKY",
  "PLS13-YEL",
  "PLS14-BLU",
  "PLS22-BPK",
  "PLS23-ORA",
  "LGT-COEX26-SHA",
  "LGT-COEX28-CGRN",
  "LGT-COEX29-MORA",
  "LGT-COEX30-DBRO",
  "8PC01-SNO",
  "8PC02-CHA",
  "8PC03-FOR",
  "8PC07-MOS",
  "8PC08-DBRO",
  "8PC09-BIS",
  "PRO-SLEE01-BLA",
  "WHL-CLA2X-BLA-L",
  "WHL-CLA2X-BLA-S",
  "COES21-NAV",
  "COES23-FOR",
  "COES24-SAN",
  "COES26-SHA",
  "COES27-YEL",
  "COES28-OLI",
  "COES29-BLU",
  "APC05-BLA-BLU",
  "APC07-BLA-YEL",
  "VOL-WKD31-BLA",
  "LGT-CHK41-NAV",
  "LGT-CHK44-SAN",
  "LGT-CHK45-CLA",
  "LGT-CHK50-DBRO",
  "LGT-PLS61-NAV",
  "LGT-PLS64-SAN",
  "LGT-PLS68-CGRN",
  "LGT-PLS70-DBRO",
  "EXP-PLS41-CHA",
  "EXP-PLS49-LTAU",
  "EVE-BPK41-NAT",
  "CPS01-SNO",
  "STR-EVE11-BLA",
  "SET-LGT-CHKCO80-DBRO",
  "SET-LGT-PLSCO80-DBRO",
  "SET-LGT-FMLY80-DBRO",
  "SET-LGTEX-CHKCO90-DBRO",
  "SET-LGTEX-PLSCO80-DBRO",
  "SET-LGTEX-FMLY80-DBRO",
  "TRK-PRO-COPB01-SAN",
  "TRK-PRO-COPB03-GRE",
  "TRK-PRO-COPB04-RED",
  "TRK-PRO-COPB05-MBLA",
  "SLG-TAG40-BLA",
  "SLG-TAG42-PLM",
  "SLG-TAG43-BRO",
  "SLG-TAG10-BLA",
  "SLG-TAG12-PLM",
  "SLG-TAG13-BRO",
  "SLG-TAG14-BLA-CORP",
  "SLG-TAG15-RED",
  "SLG-TAG22-PLM",
  "SLG-TAG23-BRO",
  "SLG-TAG25-RED",
  "SLG-TAG20-BLA-CORP",
  "SLG-TAG30-BLA",
  "SLG-TAG31-OYS",
  "SLG-TAG32-PLM",
  "SLG-TAG33-BRO",
  "SLG-TAG35-RED",
  "SLG-CARD03-PLM",
  "SLG-CARD04-BRO",
  "SLG-CARD05-RED",
  "SLG-PASS01-BLA",
  "SLG-PASS03-PLM",
  "SLG-PASS04-BRO",
  "SLG-WLT03-PLM-S",
  "SLG-WLT04-BRO-S",
  "SLG-WLT12-PLM-L",
  "SLG-WLT13-BRO-L",
  "EVETOT-MINI65-GRY",
  "SBAG01-BERLIN-L",
  "SBAG01-BERLIN-S",
  "SBAG03-CANCUN-L",
  "SBAG03-CANCUN-S",
  "SBAG05-SEOUL-S",
  "SBAG05-SEOUL-L",
  "BAG-CLA-COPB",
  "TSA-CLA-BLA",
  "BAG-CLA-CHK",
  "BAG-CLA-PLS",
  "BAG-TRK-COPB",
  "BAG-TRK-CHK",
  "TSA-LGT-BLA",
  "TSA-TRK-SAN",
  "TSA-TRK-GRY",
  "TSA-TRK-GRE",
  "FT-CLA2X-BLA",
  "FT-TRK2X-SAN",
  "FT-TRK2X-GRY",
  "FT-TRK2X-GRE",
  "FID-SNAP-BLA",
  "TRK-HING-PIN",
  "TSA-TRK-MBLA",
  "STR-BUCK-MALE",
  "TSA-CLA-BLA-LRG",
  "FT-TRK2X-RED",
  "FT-TRK2X-CBLU",
  "FT-TRK2X-BRO",
  "FT-TRK2X-MOS",
  "FT-TRK2X-MBL",
  "TSA-TRK-RED",
  "TSA-TRK-BRO",
  "TSA-TRK-MOS",
  "TSA-TRK-CBLU",
  "TSA-TRK-MBLA",
  "TSA-TRK-MBLA",
  "TSA-TRK-LGRY",
  "ZIP-PULLER-CLA",
  "CABLE-PB",
  "WHL-COPB2X-BLA",
  "WHL-CHKPLS2X-BLA",
  "WHL-COPB4X-BLA",
  "WHL-CHKPLS4X-BLA",
  "WHL-LGT2X-BLA",
  "WHL-TRK2X-SAN",
  "WHL-TRK2X-GRY",
  "WHL-TRK2X-GRE",
  "HDL-CLA-BLA",
  "DUST-COPB-CLA",
  "DUST-CHK-CLA",
  "DUST-PLS-CLA",
  "DUST-COPB-TRK",
  "PB-CAGE-CLA",
  "BOT-LID-BLA",
  "HDL-TOP-BLA",
  "CAR-TOT-STR",
  "EVE-STR-NAT",
  "WHL-LGT-PLS-CHK",
  "WHL-LGT-CHK-PLS",
  "PAD-CLA-COPB",
  "PAD-CLA-CHK",
  "PAD-CLA-PLS",
  "PAD-TRK-COPB",
  "PAD-TRK-CHK",
  "TELHDL-CLA-COPB-BLA",
  "TELHDL-CLA-CHK-BLA",
  "TELHDL-CLA-PLS-BLA",
  "TELHDL-TRK-COPB-SAN",
  "TELHDL-TRK-COPB-GRY",
  "TELHDL-TRK-COPB-GRE",
  "TELHDL-TRK-COPB-MOS",
  "TELHDL-TRK-COPB-RED",
  "TELHDL-TRK-COPB-MBLA",
  "TELHDL-TRK-COPB-BRO",
  "TELHDL-TRK-COPB-LGRY",
  "TELHDL-TRK-CHK-SAN",
  "TELHDL-TRK-CHK-GRY",
  "TELHDL-TRK-CHK-GRE",
  "TELHDL-TRK-CHK-RED",
  "TELHDL-TRK-CHK-BRO",
  "TELHDL-TRK-CHK-LGRY",
  "TRK-PLS25-MBLA",
  "TRK-PLS21-SAN",
  "TRK-PLS24-RED",
  "TRK-PLS27-BRO",
  "TRK-PLS28-MOS",
  "TRK-PLS29-LGRY",
  "JUPA-MIXS",
  "JUPA-MIXM",
  "SLG-WLT20-BLA-C",
  "SLG-WLT21-OYS-C",
  "SLG-WLT22-RED-C",
  "JETTE-TOT-LRG01-BLA",
  "JETTE-TOT-LRG02-BRO",
  "JETTE-TOT-LRG03-BONE",
  "JETTE-TOT-SML05-BLA",
  "JETTE-TOT-SML06-BRO",
  "JETTE-TOT-SML07-BONE",
  "JETTE-TOT-SML08-GRN",
  "JETTE-CRSBDY09-BLA",
  "JETTE-CRSBDY10-BRO",
  "JETTE-CRSBDY11-BONE",
  "JETTE-CRSBDY12-GRN",
  "STK-CITY-01",
  "STK-ALPH-01",
  "EVETOT-LTD-MINI69-TAN",
  "EVETOT-LTD-MINI70-BLA",
  "EVETOT2-SML37-BLA",
  "EVETOT2-SML39-MOS",
  "EVETOT2-SML43-RED",
  "EVETOT2-SML44-LBLU",
  "EVETOT2-SML45-BIS",
  "SLG-TAG36-REF",
  "TRK-COPB17-BONE-OROTON"
];

const UK = [
  "TRK-COPB02-GRY",
  "TRK-COPB13-MSAN",
  "TRK-COPB14-MGRE",
  "TRK-COPB15-MRED",
  "TRK-CHK02-GRY",
  "TRK-CHK13-MSAN",
  "TRK-CHK14-MGRE",
  "TRK-CHK15-MRED",
  "SET-TRK-CHKCOPB02-GRY",
  "SET-TRK-CHKCOPB13-MSAN",
  "SET-TRK-CHKCOPB14-MGRE",
  "SET-TRK-CHKCOPB15-MRED",
  "CME32-DBRO",
  "TRK-COPB16-MRED-RE",
  "AOC-PROV2-COPB28-CRM",
  "STR01-NAV",
  "STR02-CHA",
  "STR03-FOR",
  "STR06-SHA",
  "STR08-BLU",
  "CHK02-CHA",
  "CHK03-FOR",
  "CHK07-CLA",
  "4PC01-SNO",
  "4PC02-CHA",
  "4PC03-FOR",
  "4PC08-DBRO",
  "4PC09-BIS",
  "SET-FMLY02-CHA",
  "SET-FMLY03-FOR",
  "SET-CHKCOPB02-CHA",
  "SET-CHKCOPB03-FOR",
  "VOL-BPK33-GRY",
  "LGT-CO1-NAV",
  "LGT-CO17-DBRO",
  "COV-CLCO14-BLA",
  "COV-CLCO15-KHA",
  "COV-TRCO30-FBLU",
  "COV-TRCO31-PLUM",
  "COV-TRPLS10-BLA",
  "COV-TRPLS11-KHA",
  "TAG01-BLA",
  "TAG02-GRY",
  "BOT-SML01-BLA",
  "BOT-SML02-NAV",
  "BOT-LAR01-BLA",
  "BOT-LAR02-NAV",
  "BOT-LAR05-MOS",
  "BOT-LAR07-YEL",
  "EVETOT-LAR23-NAV",
  "EVETOT2-LAR26-NAT",
  "EVETOT2-LAR27-BLA",
  "EVETOT2-LAR29-YEL",
  "EVETOT2-LAR30-BRO",
  "EVETOT2-LAR31-GRY",
  "EVETOT2-LAR32-RED",
  "EVETOT2-LAR33-LBLU",
  "EVETOT2-LAR34-BIS",
  "PB01-BLA",
  "COPB10-GRY",
  "COPB12-PIN",
  "COPB24-DBRO",
  "PLS07-CLA",
  "PLS09-SHA",
  "PLS14-BLU",
  "PLS18-DKB",
  "LGT-COEX21-NAV",
  "LGT-COEX30-DBRO",
  "8PC02-CHA",
  "8PC08-DBRO",
  "8PC09-BIS",
  "PRO-SLEE01-BLA",
  "WHL-CLA2X-BLA-L",
  "WHL-CLA2X-BLA-S",
  "COES27-YEL",
  "LGT-CHK50-DBRO",
  "LGT-PLS68-CGRN",
  "LGT-PLS70-DBRO",
  "EXP-CHK38-DBRO",
  "EXP-PLS48-DBRO",
  "CPS02-CHA",
  "CPS01-SNO",
  "STR-EVE11-BLA",
  "PROV2-COPB22-CHA",
  "COV-CLCO26-TRA",
  "SET-LGT-CHKCO71-NAV",
  "SET-LGT-CHKCO80-DBRO",
  "SET-LGT-PLSCO71-NAV",
  "SET-LGT-PLSCO80-DBRO",
  "SET-LGT-FMLY71-NAV",
  "SET-LGT-FMLY80-DBRO",
  "SET-LGTEX-CHKCO81-NAV",
  "SET-LGTEX-CHKCO90-DBRO",
  "SET-LGTEX-PLSCO71-NAV",
  "SET-LGTEX-PLSCO80-DBRO",
  "SET-LGTEX-FMLY71-NAV",
  "SET-LGTEX-FMLY80-DBRO",
  "SLG-TAG42-PLM",
  "SLG-TAG43-BRO",
  "SLG-TAG12-PLM",
  "SLG-TAG13-BRO",
  "SLG-TAG14-BLA-CORP",
  "SLG-TAG15-RED",
  "SLG-TAG22-PLM",
  "SLG-TAG23-BRO",
  "SLG-TAG25-RED",
  "SLG-TAG20-BLA-CORP",
  "SLG-TAG31-OYS",
  "SLG-TAG32-PLM",
  "SLG-TAG33-BRO",
  "SLG-TAG35-RED",
  "SLG-CARD03-PLM",
  "SLG-CARD04-BRO",
  "SLG-CARD05-RED",
  "SLG-PASS03-PLM",
  "SLG-PASS04-BRO",
  "SLG-WLT03-PLM-S",
  "SLG-WLT04-BRO-S",
  "SLG-WLT12-PLM-L",
  "SLG-WLT13-BRO-L",
  "SLG-WLT14-RED-L",
  "EVETOT-MINI61-NAT",
  "EVETOT-MINI62-BLA",
  "EVETOT-MINI63-YEL",
  "EVETOT-MINI64-BRO",
  "EVETOT-MINI65-GRY",
  "SBAG01-BERLIN-L",
  "SBAG01-BERLIN-S",
  "SBAG03-CANCUN-L",
  "SBAG03-CANCUN-S",
  "SBAG05-SEOUL-S",
  "SBAG05-SEOUL-L",
  "BAG-CLA-COPB",
  "TSA-CLA-BLA",
  "BAG-CLA-CHK",
  "BAG-CLA-PLS",
  "BAG-TRK-COPB",
  "BAG-TRK-CHK",
  "TSA-LGT-BLA",
  "TSA-TRK-SAN",
  "TSA-TRK-GRY",
  "TSA-TRK-GRE",
  "FT-CLA2X-BLA",
  "FT-TRK2X-SAN",
  "FT-TRK2X-GRY",
  "FT-TRK2X-GRE",
  "FID-SNAP-BLA",
  "TRK-HING-PIN",
  "TSA-TRK-MBLA",
  "STR-BUCK-MALE",
  "TSA-CLA-BLA-LRG",
  "FT-TRK2X-RED",
  "FT-TRK2X-CBLU",
  "FT-TRK2X-BRO",
  "FT-TRK2X-MOS",
  "FT-TRK2X-MBL",
  "TSA-TRK-RED",
  "TSA-TRK-BRO",
  "TSA-TRK-MOS",
  "TSA-TRK-CBLU",
  "TSA-TRK-MBLA",
  "TSA-TRK-MBLA",
  "TSA-TRK-LGRY",
  "ZIP-PULLER-CLA",
  "CABLE-PB",
  "WHL-COPB2X-BLA",
  "WHL-CHKPLS2X-BLA",
  "WHL-COPB4X-BLA",
  "WHL-CHKPLS4X-BLA",
  "WHL-LGT2X-BLA",
  "WHL-TRK2X-SAN",
  "WHL-TRK2X-GRY",
  "WHL-TRK2X-GRE",
  "HDL-CLA-BLA",
  "DUST-COPB-CLA",
  "DUST-CHK-CLA",
  "DUST-PLS-CLA",
  "DUST-COPB-TRK",
  "PB-CAGE-CLA",
  "BOT-LID-BLA",
  "HDL-TOP-BLA",
  "CAR-TOT-STR",
  "EVE-STR-NAT",
  "WHL-LGT-PLS-CHK",
  "WHL-LGT-CHK-PLS",
  "PAD-CLA-COPB",
  "PAD-CLA-CHK",
  "PAD-CLA-PLS",
  "PAD-TRK-COPB",
  "PAD-TRK-CHK",
  "TELHDL-CLA-COPB-BLA",
  "TELHDL-CLA-CHK-BLA",
  "TELHDL-CLA-PLS-BLA",
  "TELHDL-TRK-COPB-SAN",
  "TELHDL-TRK-COPB-GRY",
  "TELHDL-TRK-COPB-GRE",
  "TELHDL-TRK-COPB-MOS",
  "TELHDL-TRK-COPB-RED",
  "TELHDL-TRK-COPB-MBLA",
  "TELHDL-TRK-COPB-BRO",
  "TELHDL-TRK-COPB-LGRY",
  "TELHDL-TRK-CHK-SAN",
  "TELHDL-TRK-CHK-GRY",
  "TELHDL-TRK-CHK-GRE",
  "TELHDL-TRK-CHK-RED",
  "TELHDL-TRK-CHK-BRO",
  "TELHDL-TRK-CHK-LGRY",
  "TRK-PLS21-SAN",
  "VANITY21-CRM",
  "VANITY22-LBRO",
  "VANITY23-GRP",
  "VANITY24-BLA",
  "OPEN31-CRM",
  "OPEN32-LBRO",
  "OPEN33-GRP",
  "OPEN34-BLA",
  "HANG41-CRM",
  "HANG42-LBRO",
  "HANG43-GRP",
  "HANG44-BLA",
  "EVE-CRES-81-NAT",
  "EVE-CRES-82-BLA",
  "EVE-CRES-83-BRO",
  "EVE-CRES-84-YEL",
  "EVE-BELT-91-NAT",
  "EVE-BELT-92-BLA",
  "EVE-BELT-93-BRO",
  "EVE-BELT-94-YEL",
  "SET-EXP-CHKCOPB58-DBRO",
  "SET-EXP-PLSCOPB58-DBRO",
  "SET-EXP-FMLY78-DBRO",
  "JUPA-MIXS",
  "JUPA-MIXM",
  "SLG-WLT20-BLA-C",
  "SLG-WLT21-OYS-C",
  "SLG-WLT22-RED-C",
  "SLG-KEY10-BLA",
  "SLG-KEY11-OYS",
  "SLG-KEY12-RED",
  "SLG-KEY20-BLA",
  "SLG-KEY21-OYS",
  "SLG-KEY22-RED",
  "SLG-KEY30-BLA",
  "SLG-KEY31-OYS",
  "SLG-KEY32-RED",
  "SLG-TAG50-BLA",
  "SLG-TAG51-OYS",
  "SLG-TAG52-RED",
  "SLG-TAG60-BLA",
  "SLG-TAG61-OYS",
  "SLG-TAG62-RED",
  "SLG-LAPTOP01-BLA",
  "SLG-LAPTOP02-OYS",
  "SLG-LAPTOP03-RED",
  "JETTE-TOT-LRG01-BLA",
  "JETTE-TOT-LRG02-BRO",
  "JETTE-TOT-LRG03-BONE",
  "JETTE-TOT-SML05-BLA",
  "JETTE-TOT-SML06-BRO",
  "JETTE-TOT-SML07-BONE",
  "JETTE-TOT-SML08-GRN",
  "JETTE-CRSBDY09-BLA",
  "JETTE-CRSBDY10-BRO",
  "JETTE-CRSBDY11-BONE",
  "JETTE-CRSBDY12-GRN",
  "STK-CITY-01",
  "STK-ALPH-01",
  "EVETOT-LTD-SML46-TAN",
  "EVETOT-LTD-SML47-BLA",
  "EVETOT-LTD-LAR35-TAN",
  "EVETOT-LTD-LAR36-BLA",
  "EVETOT-LTD-MINI69-TAN",
  "EVETOT-LTD-MINI70-BLA",
  "EVETOT2-SML37-BLA",
  "EVETOT2-SML39-MOS",
  "EVETOT2-SML40-YEL",
  "EVETOT2-SML41-BRO",
  "EVETOT2-SML42-GRY",
  "EVETOT2-SML43-RED",
  "EVETOT2-SML44-LBLU",
  "EVETOT2-SML45-BIS",
  "DSD-AF01",
  "SLG-TAG36-REF",
  "TRK-COPB17-BONE-OROTON",
  "SET-CHKCOPB22-BPK",
  "SET-CHKCOPB23-ORA",
  "SET-PLSCOPB22-BPK",
  "SET-PLSCOPB23-ORA",
  "SET-FMLY22-BPK",
  "SET-FMLY23-ORA",
  "SET-CHKCOPB06-MON",
  "SET-PLSCOPB06-MON",
  "SET-FMLY06-MON",
]

export default { 'en-AU': AU, 'en': US, 'en-NZ': NZ, 'en-GB': UK, 'en-CA': US };
