import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

const MainContainer = styled.div`
  position: relative;
  display: flex;
  @media (min-width: 1568px) {
    max-width: 1440px;
    margin: 0 auto;
    margin: 32px auto;
    padding: 32px;
  }
  @media (max-width: 1567px) {
    max-width: 1440px;
    margin: 32px 96px;
  }
  @media (max-width: 1050px) {
    margin: 16px;
  }
`

const CopyHeaderContainer = styled.div`
  /* Add your styling properties here */
`


const CopyHeaderLeft = styled.div`
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.44px;
  text-align: center;
  color: ${(props) => (props.textColor ? `${props.textColor};` : 'black')};;
  padding: 24px;
  font-weight: ${(props) => (props.FontWeight ? `${props.FontWeight};` : '300')};
  font-family: ${(props) => (props.Font ? `${props.Font};` : 'Objectivity')};
  width: 90%;
  font-weight: 300;
  font-style: italic;
  @media (max-width: 768px) {
    padding: 16px 0px;
    font-size: 16px;
    line-height: 1.38;
    width: 100%;
    margin-right: 0;
    display: none;
  }
`
const CopyHeaderRight = styled.div`
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.44px;
  text-align: center;
  color: ${(props) => (props.textColor ? `${props.textColor};` : 'black')};;
  padding: 24px;
  font-weight: ${(props) => (props.FontWeight ? `${props.FontWeight};` : '300')};
  font-family: ${(props) => (props.Font ? `${props.Font};` : 'Objectivity')};
  width: 90%;
  font-weight: 300;
  font-style: italic;
  @media (max-width: 768px) {
    padding: 16px 0px;
    font-size: 16px;
    line-height: 1.38;
    width: 100%;
    margin-left: 0;
  }
`

const SectionCopy = styled.div`
  width: 40%;
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: left;
    z-index: 2;
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;justify-content: center;' : ''}
  }
  @media (max-width: 768px) {
    display: ${(props) => (props.hideMobile && 'none;')};
    margin-top: 20px;
  }
`
const SectionCopyText = styled.div`
  width: 60%;
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: left;
    z-index: 2;
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;justify-content: center;' : ''}
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const SectionImageLeft = styled(GatsbyImage)`
  width: 80%;
  height: 100%;
  object-fit: contain;
  text-align: left;
  ${(props) => props.roundImage && `border-radius: 10px;`}
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
  }
`
const SectionImageRight = styled(GatsbyImage)`
  width: 80%;
  height: 100%;
  object-fit: contain;
  text-align: right;
  ${(props) => props.roundImage && `border-radius: 10px;`}
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
  }
`

const SideImagesSectionHeritage = ({ data }) => {
  return (
    <MainContainer>
      {data?.AssetFirst && (
        <>
          <SectionCopy hideMobile={data?.AssetSecond} style={{ 'align-items': 'start' }}>
            {data?.AssetFirst && (
              <>
                <SectionImageLeft
                  roundImage={data.roundImage}
                  padding={data?.padding}
                  alt={data?.AssetFirst?.alternativeText}
                  image={withArtDirection(
                    getImage(data?.AssetFirst.file?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          data?.AssetFirst.file?.childImageSharp?.gatsbyImageDataMobile
                        ),
                      },
                    ]
                  )} />
              </>
            )}
          </SectionCopy>
          <SectionCopyText hideMobile={data?.AssetSecond} center={true}>
            {!data?.AssetSecond && (
              <CopyHeaderRight Font={data?.Font} FontSize={data?.FontSize} textColor={data.textColor}>{data?.HeaderFirst}</CopyHeaderRight>
            )}
          </SectionCopyText>
        </>
      )}
      {data.AssetSecond && (
        <>
          <SectionCopyText hideMobile={data?.AssetFirst}>
            {!data.AssetFirst && (
              <CopyHeaderLeft Font={data?.Font} FontSize={data?.FontSize} textColor={data.textColor}>{data?.HeaderSecond}</CopyHeaderLeft>
            )}
          </SectionCopyText>
          <SectionCopy hideMobile={data?.AssetFirst} style={{ 'align-items': 'end' }}>
            {data?.AssetSecond && (
              <>
                <SectionImageRight
                  roundImage={data.roundImage}
                  padding={data?.padding}
                  alt={data?.AssetSecond?.alternativeText}
                  image={withArtDirection(
                    getImage(data?.AssetSecond.file?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          data?.AssetSecond.file?.childImageSharp?.gatsbyImageDataMobile
                        ),
                      },
                    ]
                  )}
                />
              </>
            )}
          </SectionCopy>
        </>
      )}

    </MainContainer>
  )
}
export default SideImagesSectionHeritage
