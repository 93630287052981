import styled from '@emotion/styled'
import React from 'react'

const HeaderConatiner = styled.div`
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor};` : '#1f3e3c')};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media (min-width: 800px) {
    ${({ tall }) => tall && 'padding: 96px 0;'}

  }
`
const TitleHeader = styled.h1`
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.7px;
  text-align: center;
  padding-bottom: ${(props) => (props.subHeader ? `20px;` : '')};
  color: ${(props) => (props.textColor ? `${props.textColor};` : 'white;')};
  @media (max-width: 800px) {
    font-size: 38px;
    padding-bottom: ${(props) => (props.subHeader ? `20px;` : '')};
  }
`
const TitleSubHeading = styled.h2`
  opacity: 0.8;
  color: ${(props) => (props.textColor ? `${props.textColor};` : 'black;')};
  font-family: Objectivity;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px;` : '19px;')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.14px;
  text-align: center;
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 800px) {
    /* display: none */
    font-size: 14px;
  }
`
const CopyWidthContainer = styled.div`
  padding: 40px 20px;
`
const SmallSubHeading = styled.div`
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.71px;
  color: black;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  @media (max-width: 1025px) {
    padding: 0;
  }
`

const StaticHeader = ({ data }) => {
  const headingCopy = data?.HeaderCopy
  var containerId = headingCopy?.toLowerCase()?.replace(/\s+/g, '-')?.replace('.', '');
  return (
    <HeaderConatiner backgroundColor={data?.backgroundColor}>
      <div id={containerId}></div>
      <CopyWidthContainer>
        {data?.SubHeading && (
          <SmallSubHeading>{data.SubHeading}</SmallSubHeading>
        )}
        <TitleHeader textColor={data?.textColor} subHeader={data?.SubHeaderCopy}> {data?.HeaderCopy} </TitleHeader>
        <TitleSubHeading textColor={data?.textColor} fontSize={data?.SubHeaderFontSize}> {data?.SubHeaderCopy} </TitleSubHeading>
      </CopyWidthContainer>
    </HeaderConatiner>
  )
}

export default StaticHeader
