import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import Video from '../Video'

const SectionContainer = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${({ color }) => color};
  flex-direction: ${(props) => (props.reverse ? 'row' : 'row-reverse')};
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1025px) {
    max-width: 90vw;
    justify-content: center;
    align-items: center;
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
  }
`
const HeaderImage = styled(GatsbyImage)`
  width: 50%;
  border-radius: 10%;
  margin: 2.5%;
  ${(props) => (props.space === 'true' ? 'margin: 8%;width: 34%;' : '')}
  @media (min-width: 1026px) {
    ${(props) => (props.toppadding === 'true' ? 'margin: 5% 0;' : '')}
  }
  @media (max-width: 1025px) {
    width: 100%;
    max-height: 100vw;
    ${(props) => (props.space === 'true' ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const HeaderVideo = styled(Video)`
  width: 50%;
  border-radius: 10%;
  margin: 2.5%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const SectionCopy = styled.div`
border-radius: 10%;
  margin: 2.5%;
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1025px) {
    padding: 20px;
    width: 100%;
    text-align: center;
    z-index: 2;
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) => (props.center ? 'text-align: center; align-items: center;' : '')}
  }

  @media (min-width: 1026px) {
    background-color: #EDE9E3;
  }
`
const CopyWidthContainer = styled.div`
  @media (min-width: 1025px) {
    max-width: 80%;
    ${(props) => (props.TextAlignment ? `text-align: ${props.TextAlignment};` : '')};
  }
`
const CopyHeading = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.11px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  justify-content: center;
  @media (max-width: 1025px) {
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  }
`
const CopyBody = styled.div`
  margin: 16px 0;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.12px;
  color: rgb(var(--black));
  text-align: center;
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.07px;
    color: rgb(var(--black));
    margin-right: 48px;
    margin: 0;
    padding: 0 20px;
  }

  & a {
    text-decoration: underline !important;
  }

  & p {
	  margin: 16px 0;
	}
`

const PaddedSplitPanel = ({ data }) => {
  return (
    <SectionContainer
      mobilePadding={true}
      reverseMobile={data.MobilePosTop}
      reverse={data.DesktopPosLeft}
    // color={data.BackgroundColour || 'white'}
    >
      {data.MediaFile && (
        data?.MediaFile?.mime?.includes('video') || data?.MediaFile?.mime?.includes('gif') ? (
          <HeaderVideo
            // padding={data.ImagePadding}
            alt={data.MediaFile?.alternativeText || data.MediaFile?.name}
            src={data.MediaFile?.file?.publicURL}
          />
        ) : (
          <HeaderImage
            space={data.ImagePadding ? 'true' : 'false'}
            loading="lazy"
            toppadding={data.TopBottomPadding}
            alt={data.MediaFile?.alternativeText || data.MediaFile?.name}
            image={withArtDirection(getImage(data.MediaFile?.file?.childImageSharp?.gatsbyImageData), [
              {
                media: '(max-width: 1024px)',
                image: getImage(data.MediaFile?.file?.childImageSharp?.gatsbyImageDataMobile),
              },
            ])}
          />
        )
      )}
      <SectionCopy left={true}>
        <CopyWidthContainer>
          <>
            {
              data.Heading && (
                <CopyHeading color="black" me={true}>
                  {data.Heading}
                </CopyHeading>
              )
            }
            {
              data.Body && (
                <CopyBody dangerouslySetInnerHTML={{ __html: data.Body.replace(/&nbsp;/g, '') }} />
              )
            }
          </>
        </CopyWidthContainer>
      </SectionCopy>
    </SectionContainer>
  )
}

export default PaddedSplitPanel
