import React, { useEffect } from 'react'
import LayoutComponent from '../components/Layout/index'
import { globalHistory } from '@reach/router'
import '../styles/global.css'

function Layout({ children, pageContext, location }) {
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'POP') {
      }
    })
  }, [])
  return (
    <LayoutComponent pageContext={pageContext} location={location}>
      <span id="top" />
      {children}
    </LayoutComponent>
  )
}

export default Layout
