import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import { trackSelectPromotion } from '../../../lib/ga4'
import Link from '../../Link/index'
import Video from '../Video'

const SectionContainer = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${({ color }) => color};
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  @media (max-width: 1025px) {
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
  }
  ${({maxWidth}) => maxWidth && 'max-width: 1440px; margin: 0 auto;'}
`
const HeaderImage = styled(GatsbyImage)`
  width: 50%;
  ${(props) => (props.space === 'true' ? 'margin: 8%;width: 34%;' : '')}
  @media (min-width: 1026px) {
    ${(props) => (props.toppadding ? 'margin: 5% 0;' : '')}
  }
  @media (max-width: 1025px) {
    width: 100%;
    max-height: 100vw;
    ${(props) => (props.space === 'true' ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const HeaderVideo = styled(Video)`
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const SectionCopy = styled.div`
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1025px) {
    padding: 32px;
    width: 100%;
    text-align: center;
    z-index: 2;
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) => (props.center ? 'text-align: center; align-items: center;' : '')}
  }
`
const CopyWidthContainer = styled.div`
  @media (min-width: 1025px) {
    max-width: 80%;
    ${(props) => (props.TextAlignment ? `text-align: ${props.TextAlignment};` : '')};
  }
  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`
const SmallSubHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.71px;
  color: rgb(var(--${(props) => props.color}));
  padding: 16px 0;
  text-transform: uppercase;
  @media (max-width: 1025px) {
    padding: 0;
  }
`
const CopyHeading = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.39px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  padding: 16px 0;
  @media (max-width: 1025px) {
    padding: 0;
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  }
`
const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  border: solid 1px black;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.45px;
  text-align: center;
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  background: transparent;
  height: 49px;
  padding: 0 48px;
  outline: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
    line-height: 42px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : 'margin: 16px 0;')}

  transition: all 0.2s;

  &:hover {
    background-color: white;
    color: black;
    border-color: rgba(var(--dark-blue-grey), 1);
  }
  &:active {
    background-color: rgba(var(--dark-blue-grey), 1);
    color: ${(props) => (props.text ? `rgb(var(--${props.text}))` : `white`)};
    border-color: transparent;
  }
`
const CopyBody = styled.div`
  margin: 16px 0;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.12px;
  color: rgb(var(--black));
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.07px;
    color: rgb(var(--black));
    margin-right: 48px;
    margin: 0;
    padding: 0 20px;
    line-height: 2;
  }

  & a {
    text-decoration: underline !important;
  }

  & p {
	  margin: 16px 0;
	}
`
const StaticHeaderImage = styled.img`
  width: 50%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`

const SplitPanel = ({ data }) => {
  return (
    <SectionContainer
      mobilePadding={true}
      reverseMobile={data.MobileImageOn === 'Bottom'}
      reverse={data.DesktopImageOn === 'Right'}
      color={data.BackgroundColour || 'white'}
      maxWidth={data.MaxWidth}
    >
      {
        data.Image?.isStatic ? (
          <StaticHeaderImage src={data.Image?.url} style={{ margin: data.ImagePadding ? '8%' : '', width: data.ImagePadding ? '34%' : '' }}
          />
        ) : (
          <>
            {data.Image && (
              data?.Image?.mime?.includes('video') || data?.Image?.mime?.includes('gif') ? (
                <HeaderVideo
                  padding={data.ImagePadding}
                  alt={data.Image?.alternativeText || data.Image?.name}
                  src={data.Image?.file?.publicURL}
                />
              ) : (
                <HeaderImage
                  space={data.ImagePadding ? 'true' : 'false'}
                  loading="lazy"
                  toppadding={data.TopBottomPadding}
                  alt={data.Image?.alternativeText || data.Image?.name}
                  image={withArtDirection(getImage(data.Image?.file?.childImageSharp?.gatsbyImageData), [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(data.Image?.file?.childImageSharp?.gatsbyImageDataMobile),
                    },
                  ])}
                />
              )
            )}
          </>
        )
      }

      <SectionCopy left={true}>
        <CopyWidthContainer TextAlignment={data.TextAlignment}>
          {data.SubHeading && <SmallSubHeading color="black">{data.SubHeading}</SmallSubHeading>}
          {data.TitleImage ? (
            <CopyHeading large={true} color="white" style={{ justifyContent: 'center' }}>
              <img
                loading="lazy"
                style={{ margin: '0px', objectFit: 'contain', maxHeight: '150px', width: '90%', height: 'auto' }}
                src={data.TitleImage.file.publicURL}
                alt={data.TitleImage.alternativeText || data.TitleImage?.name}
              />
            </CopyHeading>
          ) : (
            <>
              {
                data.Heading && (
                  <CopyHeading color="black" me={true}>
                    {data.Heading}
                  </CopyHeading>
                )
              }
            </>
          )}
          {
            data.Body && (
              <CopyBody dangerouslySetInnerHTML={{ __html: data.Body.replace(/&nbsp;/g, '') }} />
            )
          }
          {data?.CTACopy && (
            <Link to={data.to}>
                <TransparentButton color="black" onClick={() => { trackSelectPromotion({ creative_name: data.Heading, promotion_id: data.id }) }}>
                  {' '}
                  {data.CTACopy}{' '}
                </TransparentButton>
            </Link>
          )}
        </CopyWidthContainer>
      </SectionCopy>
    </SectionContainer>
  )
}

export default SplitPanel
