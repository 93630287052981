import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import ShopPanel from '../../panels/ShopPanels/shop_panel_slice'

const loadScript = () => {
  var n = document.createElement('script')
  n.type = 'text/javascript'
  n.async = !0
  n.src = '/quiz-api.js'
  var a = document.getElementsByTagName('script')[0]
  a.parentNode.insertBefore(n, a)
}

const StylingOverride = styled.div`
  & .c-addToCart {
    display: none !important;
  }

  & .l-products {
    display: none !important;
  }

  & .c-resultPanel-section {
    display: none !important;
  }

  & .c-header {
    display: none !important;
  }

  & .c-tagInput-tag {
    border: black;
  }

  & .c-quiz--embed {
    max-width: 800px !important;
    margin: 0 auto !important;
  }

  & .c-questionsForm-next {
    background: black !important;
    color: white !important;
    border-radius: 32px !important;
    padding: 12px 32px !important;
  }
  & .c-questionsForm-next:hover {
    background: #000000AA !important;
  }

  & .okeConnect.okeQuizzes .c-tagInput-input:checked+.c-tagInput-label {
    background: #000000 !important;
  }
  & .okeConnect.okeQuizzes .c-tagInput-label {
    border-radius: 32px !important;
  }
  & .okeConnect.okeQuizzes .c-title.c-questions-header-title {
    color: #000000 !important;
  }
  margin-bottom: 32px;
`

const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  border: solid 1px black;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.45px;
  text-align: center;
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  background: transparent;
  height: 49px;
  padding: 0 48px;
  outline: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
    line-height: 42px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : 'margin: 16px 0;')}

  transition: all 0.2s;

  &:hover {
    background-color: white;
    color: black;
    border-color: rgba(var(--dark-blue-grey), 1);
  }
  &:active {
    background-color: rgba(var(--dark-blue-grey), 1);
    color: ${(props) => (props.text ? `rgb(var(--${props.text}))` : `white`)};
    border-color: transparent;
  }
`

const OkendoQuiz = ({ data }) => {
  const { quizId, subscriberId, completedHeading } = data
  const [loaded, setLoaded] = useState(false)
  const [productIds, setProductIds] = useState()
  const [subtitleText, setSubtitleText] = useState()

  
  useEffect(() => {
    const fetchData = async () => {
      if (!loaded) {
        setLoaded(true)
        loadScript()

        if (!window.okeConnectApi) {
          window.okeConnectApi = {}
        }

        window.okeConnectApi.recommendedProductsMounted = (
          productArea,
          products
        ) => {
          setProductIds(
            products.map(({ productId }) => productId.replace('shopify-', ''))
          )

          const element = document.querySelector('.c-resultBlock-subtitle.c-subtitle');
          if (element) {
              // Set the text content of the element to the state
              setSubtitleText(element.textContent);
          }

        }
      }
    }
    fetchData()
  }, [loaded])

  const refreshPage = () => {
    window.location.reload()
  }
  return (
    <StylingOverride>
      <div
        oke-quiz=" "
        oke-channel-quiz-id={quizId}
        oke-subscriber-id={subscriberId}
      />
      {productIds && (
        <>
          <ShopPanel
            data={{
              productIds: productIds,
              subHeader: completedHeading,
              header: subtitleText
            }}
          />
          <div
            className='flex items-center justify-center mb-6'
          >
            <TransparentButton
              color="black"
              onClick={refreshPage}
            >
              Retake Quiz.
            </TransparentButton>
          </div>
        </>
      )}
    </StylingOverride>
  )
}
export default OkendoQuiz
