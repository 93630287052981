import React from 'react'
import ShopPanel from '../ShopPanel/shop-panel'

const RetailPanel = ({data}) => {
    return (
        <ShopPanel
            link={'/store/'} 
            locationData={data?.Locations} 
        />
    )
}

export default RetailPanel