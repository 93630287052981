import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
const fadeOut = keyframes`
from {
    opacity: 1;
}
to {
    opacity: 0;
}
`;
const HeaderConatiner = styled.div`
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor};` : '#1f3e3c')};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 40px 0;
`
const TitleHeader = styled.h1`
  display: flex;
  jusify-content: center;
  align-items: center;
  min-height: 3em;
  padding: 0 1em;
  font-size: 32px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.7px;
  text-align: center;
  padding-bottom: ${(props) => (props.subHeader ? `20px;` : '')};
  color: #000;
    @media (max-width: 768px) {
        min-height: 4em;
        display: block;
        font-size: 24px;
    }
  ${(props) =>
        props.visible
            ? css`
        animation: ${fadeIn} 1s linear forwards;
    `
            : css`
        animation: ${fadeOut} 1s linear forwards;
    `}
`;
const CopyWidthContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        padding: 16px;
        display: block;
    }
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const BorderBottom = styled.div`
    width: 100%;
    border-bottom: 1px solid black;
    ${(props) =>
        props.isActive
            ? css`
              animation: ${fadeIn} 0.3s linear forwards;
          `
            : css`
              animation: ${fadeOut} 0.1s linear forwards;
        `}
`;
const StaticHeaderImage = styled.img`
    padding: 20px;
    margin: 0 10px;
    height: 125px;
    width: auto;

    @media (max-width: 768px) {
        height: 90px;
    }
`

const ImageTextPanel = ({ backgroundColor = "#fff", images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTitleVisible, setIsTitleVisible] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsTitleVisible(false);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
                setIsTitleVisible(true);
            }, 200);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <HeaderConatiner backgroundColor={backgroundColor}>
            <TitleHeader visible={isTitleVisible}>
                {images[currentIndex].text}
            </TitleHeader>
            <CopyWidthContainer>
                {
                    images.map((item, index) => {
                        return (
                            <ImageContainer key={index}>
                                <StaticHeaderImage loading='lazy' src={item.image?.file?.publicURL} alt={`Image ${index}`} onClick={(e) => setCurrentIndex(index)} />
                                <BorderBottom isActive={index === currentIndex} />
                            </ImageContainer>
                        )
                    })
                }
            </CopyWidthContainer>
        </HeaderConatiner>
    );
};

export default ImageTextPanel;