import styled from '@emotion/styled'
import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ChevronRight from '../../images/storybook-images/panelsV2/footer/chevron-right-black.svg'


const ContentBlockData = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: ${(props) => (props.width ? `${props.width}%` : '50%')};

  @media (min-width: 1026px) {
    ${(props) => (props.contentLeft ? 'justify-content: start; align-items: start;' : 'justify-content: end; align-items: end;')};
    padding: 40px;
  }
`
const Content = styled.div`
  display: flex;
  background-color: #B5B788;
  justify-content: center;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 52px;
  color: black;
  padding: 16px;
  margin: 0 auto;
  text-align: start;
  color: #ffffff;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
    padding-bottom: 12px;
  }
`
const ErrorMsg = styled.div`
  font-weight: 500;
  color: rgb(var(--terracota));
  margin: 10px;
  text-align: center;
`
const SignupContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 16px;
  }
`
const EmailButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;
  @media (max-width: 1025px) {
    flex-direction: column;
    margin: auto;
  }
`
const TextInputContainer = styled.div`
  background-color: #fff;
  padding: 8px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-grow: 1;
  // border-radius: 50px;
  margin-right: 20px;
  @media (max-width: 1025px) {
    margin-right: 0;
    width: 300px;
    justify-content: space-between;
  }
`
const TextInput = styled.input`
  font-size: 16px;
  font-family: Objectivity;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  background-color: transparent;
  border: none;
  outline: none;
  width: 300px;
  margin-left: 8px;
  color: black;
  font-weight: 500;
  height: 48px;
  padding: 0 16px;
  @media (max-width: 1025px) {
    text-align: left;
    font-size: 18px;
    width: 100%;
  }

  ::placeholder {
    color: black; // Set the placeholder text color to white
    opacity: 0.5; // You can adjust the opacity if needed
  }
`
const SignTransparentButton = styled.div`
  border-radius: 27px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  background: left;
  height: 48px;
  padding: 0 16px;
  outline: none;
  color: #000000;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 1025px) {
    color: black;
  }
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(grey, 0.2);
    color: black;
    border-color: white;
  }
  &:hover {
    background-color: rgba(grey, 1);
    color: black;
    border-color: white;
  }
`
const Body = styled.div`
  font-size: 18px;
  padding: 16px;
  max-width: 800px;
  align-items: center;
  text-align: center;
  line-height: 2;
  color: #fff;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`
const TickerContainer = styled.div`
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100vw;
  }
`
const ImageLogo = styled(GatsbyImage)`
  width: 70%;
`

const TickerHeading = ({ title, color, BGcolor, size }) => {
  const firstWord = title?.split(' ')[0]
  const restOfTitle = title
    ?.split(' ')
    .filter((_, key) => key > 0)
    .join(' ')

  const { first, second, third, fourth } = useStaticQuery(graphql`
  {
    first: file(relativePath: { eq: "custombanners/olympics/ticker1.svg" }) {
      publicURL
    }
    second: file(relativePath: { eq: "custombanners/olympics/ticker2.svg" }) {
      publicURL
    }
    third: file(relativePath: { eq: "custombanners/olympics/ticker3.svg" }) {
      publicURL
    }
    fourth: file(relativePath: { eq: "custombanners/olympics/ticker4.svg" }) {
      publicURL
    }
  }
`)

  const images = [first, second, third, fourth];

  return (
    <div style={{ position: 'relative' }}>
      <div id={`banner-${title}`} style={{ position: 'absolute', height: 1 }} />
      <div className="ticker animate ticker_list" style={{ margin: 0, backgroundColor: BGcolor }}>
        <div className="ticker_list">
          <div style={{ display: 'flex' }}>
            {images.map((image) => (
              <img src={image?.publicURL} style={{ width: 600, margin: 40, marginTop: 0 }} />
            ))}
            {images.map((image) => (
              <img src={image?.publicURL} style={{ width: 600, margin: 40, marginTop: 0 }} />
            ))}
            {images.map((image) => (
              <img src={image?.publicURL} style={{ width: 600, margin: 40, marginTop: 0 }} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const SignUpTickerBlock = ({ data }) => {
  let [email, setEmail] = useState('')
  let [sent, setSent] = useState(false)
  let [emailValid, setEmailValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  let [consent, setConsent] = useState(true)

  const handleChange = (e) => {
    setEmail(e.target.value)
    validateEmail(e.target.value)
  }
  const handleEnter = async () => {
    if (validateEmail(email)) {
      await klavyioSignUp({
        email,
        signupID: data?.SignUpId,
        source: 'Signup Footer',
      })
      trackSignUp({ method: email.includes('@') ? 'email' : 'phone' })
      setEmail('')
      setSent(true)
      onSubmit()
    }
  }

  const add = (event) => {
    if (event.keyCode === 13) {
      handleEnter()
    }
  }

  const validateEmail = (value) => {
    const regex =
      /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // eslint-disable-line
    if (value.length === 0) {
      setEmailValid(false)
      setErrorMessage('Please enter an email address.')
      return false
    } else if (!regex.test(value)) {
      setEmailValid(false)
      setErrorMessage('Please enter a valid email address.')
      return false
    } else if (!consent) {
      setErrorMessage('Please consent to the terms and conditions.')
      setEmailValid(false)
      return
    }
    setEmailValid(true)
    setErrorMessage()
    return true
  }
  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <Content>
        <ContentBlockData contentLeft={true} width={100}>
          <Title>{data?.Title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: data?.Body }} />
          <SignupContent>
            <EmailButtonContainer>
              {sent ? (
                <snap>
                  <br /> {' '}Thanks for signing up{' '}
                </snap>
              ) : (
                <div style={{ display: 'block' }}>
                  <TextInputContainer>
                    <TextInput
                      value={email}
                      onKeyDown={add}
                      onChange={handleChange}
                      placeholder={'Your email'}
                    />
                    <SignTransparentButton onClick={handleEnter}>
                      {onSubmit && <span style={{ fontWeight: 500 }}>Next</span>}<img src={ChevronRight} alt={'ChevronRight'} style={{ width: 20 }} />
                    </SignTransparentButton>
                  </TextInputContainer>
                </div>
              )}
            </EmailButtonContainer>
            {
              (!emailValid) && (
                <ErrorMsg>
                  {errorMessage}
                </ErrorMsg>
              )
            }
          </SignupContent>
        </ContentBlockData>
      </Content>
      <TickerContainer>
        <TickerHeading title={"Carry our cheers."} BGcolor={"#B5B788"} color={"#FFFFFF"} />
      </TickerContainer>
    </>
  )
}

export default SignUpTickerBlock
