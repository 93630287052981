import styled from '@emotion/styled';
import Video from '../../panels/Video'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import Link from '../../Link';


const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 2px;
  @media (min-width: 768px) {
    & > :nth-of-type(1),
    & > :nth-of-type(2) {
      flex: 0 1 calc(50%  - 2px);
    }

    & > :nth-of-type(3) ,
    & > :nth-of-type(4),
    & > :nth-of-type(5) {
      flex: 0 1 calc(33.3333% - 1.4px);
    }
  }

  @media (max-width: 767px) {
    & > :nth-of-type(1) {
      flex: 0 1 100%;
    }

    & > :nth-of-type(2),
    & > :nth-of-type(3),
    & > :nth-of-type(4),
    & > :nth-of-type(5) {
      flex: 0 1 calc(50%  - 4px);
    }
  }
`;

const Tile = styled(Link)`
  disaplay: block;
  position: relative;

  background-color: #ccc;
  aspect-ratio: 0.7;
  max-height: calc(100vh - 33px - 60px);
`;

const TileText = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
`;

const TileImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const TileVideo = styled(Video)`
  height: 100%;
  width: 100%;
`

const HomepageTiles = ({data}) => {
  return (
    <TilesContainer>
      {
        data?.Tiles?.map(tile => 
          <Tile to={tile?.link}>
            {
              tile.Media?.mime?.includes('video') ? (
                <TileVideo
                  alt={tile.Media?.alternativeText || tile.Media?.name}
                  src={tile.Media?.file.publicURL}
                  loading='eager'
                />
              ) : (
                <TileImage
                  alt={tile.Media?.alternativeText || tile.Media?.name}
                  image={withArtDirection(
                    getImage(tile.Media?.file?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          tile.Media?.file?.childImageSharp?.gatsbyImageDataMobile ? tile.Media?.file?.childImageSharp?.gatsbyImageDataMobile : tile.Media?.file?.childImageSharp?.gatsbyImageDataMobile
                        ),
                      },
                    ]
                  )}
                  style={{ objectFit: 'contain' }}
                />
              )  
            }
            <TileText> {tile?.LinkText} </TileText>
          </Tile>
        )
      }
    </TilesContainer>
  );
}

export default HomepageTiles;


