export const trackAddToCart = (data) => {
    const { currency, value, items, sticky } = data

    window.gtag?.('event', 'add_to_cart', {
        currency: currency,
        value: value,
        items: items,
        sticky: sticky,
    });

    // window.hj?.('event', 'add_to_cart', {
    //     currency: currency,
    //     value: value,
    //     items: items,
    //     sticky: sticky,
    // });
}
export const trackBeginCheckout = (data) => {
    const { items, value, currency } = data

    window.gtag?.('event', 'begin_checkout', {
        currency: currency,
        value: value,
        items: items
    });

    // window.hj?.('event', 'begin_checkout', {
    //     currency: currency,
    //     value: value,
    //     items: items
    // });
}
export const trackSignUp = (data) => {
    const { method } = data

    window.gtag?.('event', 'sign_up', {
        method: method
    });

    // window.hj?.('event', 'sign_up', {
    //     method: method
    // });
}
export const trackViewItemList = (data) => {
    const { items, currency } = data

    window.gtag?.('event', 'view_item_list', {
        currency: currency,
        items: items,
    });

    // window.hj?.('event', 'view_item_list', {
    //     currency: currency,
    //     items: items,
    // });
}
export const trackRemoveFromtCart = (data) => {
    const { currency, value, items } = data

    window.gtag?.('event', 'remove_from_cart', {
        currency: currency,
        value: value,
        items: items
    });

    // window.hj?.('event', 'remove_from_cart', {
    //     currency: currency,
    //     value: value,
    //     items: items
    // });
}
export const trackViewItem = (data) => {
    const { currency, value, items } = data

    window.gtag?.('event', 'view_item', {
        currency: currency,
        value: value,
        items: items
    });

    // window.hj?.('event', 'view_item', {
    //     currency: currency,
    //     value: value,
    //     items: items
    // });
}
export const trackSelectPromotion = (data) => {
    const { creative_name, promotion_id } = data

    window.gtag?.('event', 'select_promotion', {
        creative_name: creative_name,
        promotion_id: promotion_id,
    });

    // window.hj?.('event', 'select_promotion', {
    //     creative_name: creative_name,
    //     promotion_id: promotion_id,
    // });
}
// export const trackSelectItem = (data) => {
//     const { currency, value, items } = data

//     window.gtag?.('event', 'select_item', {
//         currency: currency,
//         value: value,
//         items: items
//     });
// }
export const trackUpsellModule = (data) => {
    const { items, value, currency } = data

    window.gtag?.('event', 'upsell_item', {
        currency: currency,
        value: value,
        items: items
    });

    // window.hj?.('event', 'upsell_item', {
    //     currency: currency,
    //     value: value,
    //     items: items
    // });
}
export const trackStartPers = (data) => {
    const { items, value, pet, currency } = data

    window.gtag?.('event', 'start_pers', {
        currency: currency,
        value: value,
        items: items,
        pet: pet
    });

    // window.hj?.('event', 'start_pers', {
    //     currency: currency,
    //     value: value,
    //     items: items,
    //     pet: pet
    // });
}
export const trackSetSaveBundle = (data) => {
    const { items, value, currency } = data

    window.gtag?.('event', 'set_save_bundle', {
        currency: currency,
        value: value,
        items: items
    });

    // window.hj?.('event', 'set_save_bundle', {
    //     currency: currency,
    //     value: value,
    //     items: items
    // });
}
export const trackViewCart = (data) => {
    const { currency, value, items } = data

    window.gtag?.('event', 'view_cart', {
        currency: currency,
        value: value,
        items: items
    });

    // window.hj?.('event', 'view_cart', {
    //     currency: currency,
    //     value: value,
    //     items: items
    // });
}

export const trackingCorpFormSubmit = (data) => {
    const { region } = data
    const time = Date.now()

    window.gtag?.('event', 'corp_submit', {
        region: region,
        time: time
    });
}