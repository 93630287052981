import React from 'react'
import { Slice } from 'gatsby'

const DetailedShopExplore = ({ data }) => {
  return (
    <Slice
        panelData={data}
        alias={`detailed_shop_panel`}
    />
  )
}

export default DetailedShopExplore
