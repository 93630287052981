import React from 'react'
import SplitPanel from './split_panel'

const SplitPanelV2 = ({data}) => {
    return (
        <SplitPanel
            header={data?.Subheading} 
            title={data?.Heading} 
            body={data?.Body} 
            // link={splitPanel1?.link} 
            addPersonalisationOptionOn={false} 
            color={data?.Theme?.panel_theme?.Background} 
            font={'retro'} 
            Image={data?.Image} 
            reverse={data?.DesktopImagePosition}
            link={{
                to: data.link,
                label: data?.CTA
            }}
            overlapCompensation={data?.overlapCompensation}
        />
    )
}

export default SplitPanelV2