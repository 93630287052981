import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { React, useEffect, useState } from 'react'
import Video from '../Video'
import { debounce } from 'lodash'

const SectionContainerTop = styled.div`
  background-color: ${(props) => props.color};
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  ${(props) =>
    props.HeightPercentage
      ? `height: calc(${props.HeightPercentage}vh - 126px);`
      : 'height: calc(100vh);'}
  @media (max-width: 768px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
    ${(props) => (!props.noMin && !props.HeightPercentage ? 'height: calc(100vh);' : '')}
  }
  @media (min-width: 1024px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }
`
const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : '')}
  }
`
const HeaderVideo = styled(Video)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : '')}
  }
`
const SectionCopy = styled.div`
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: rgb(var(--${(props) => props.color}));

  @media (min-width: 1026px) {
    ${({ trunk }) => trunk && `align-items: flex-end; text-align: center; padding-right: 10%;`}
    ${({ school }) => school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
  }
  @media (max-width: 1025px) {
    width: 100%;
    ${({ noMin }) => noMin && 'height : 100%;'}

    text-align: left;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;justify-content: center;' : ''}
    ${({ trunk }) => trunk && `align-items: center;justify-content: center; text-align: center; `}
    ${(props) => (props.mobileAbsolute ? 'position: absolute; top: 22px; padding-top: 8px;' : '')}
  }
  @media (max-width: 768px) {
    ${({ noMin }) => !noMin && 'height : 60vh;'}
  }
  @media (max-width: 320px) {
    ${({ noMin }) => !noMin && 'height : 50vh;'}
  }
`
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 200);

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const TwoAssetsBanner = ({ data }) => {
  let size = useWindowSize();

  const secondAssetDesktop = data.SecondAsset
  const secondAssetMobile = data.MobileSecondAsset
  const secondAsset = size.width > 1025 ? secondAssetDesktop : secondAssetMobile
  const firstAssetDesktop = data.FirstAsset
  const firstAssetMobile = data.MobileFirstAsset
  const firstAsset = size.width > 1025 ? firstAssetDesktop : firstAssetMobile

  return (
    <SectionContainerTop>
      <SectionCopy style={{ height: size.width < 768 && '100vh' }}>
        {(data.SecondAsset?.isStatic && data.MobileSecondAsset?.isStatic) ? (
          <StaticHeaderImage src={size.width > 1025 ? secondAssetDesktop?.url : secondAssetMobile?.url} />
        ) : (
          secondAsset.mime?.includes('video') ? (
            <HeaderVideo
              alt={secondAsset?.alternativeText}
              src={secondAsset.file.publicURL}
            />
          ) : (
            (secondAssetDesktop?.file?.childImageSharp?.gatsbyImageData && secondAssetMobile?.file?.childImageSharp?.gatsbyImageData) && (
              <HeaderImage
                alt={data.SecondAsset?.alternativeText}
                image={withArtDirection(
                  getImage(secondAssetDesktop.file.childImageSharp.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(
                        secondAssetMobile.file.childImageSharp.gatsbyImageDataMobile
                      ),
                    },
                  ]
                )}
                style={{ objectFit: 'contain' }}
              />
            )
          )
        )}
      </SectionCopy>
      <SectionCopy style={{ display: size.width < 768 && 'none' }}>
        {(data.FirstAsset?.isStatic && data.MobileFirstAsset?.isStatic) ? (
          <StaticHeaderImage src={size.width > 1025 ? firstAssetDesktop?.url : firstAssetMobile?.url} />
        ) : (data.FirstAsset.mime?.includes('video') ? (
          <HeaderVideo
            alt={firstAsset.alternativeText}
            src={firstAsset.file.publicURL}
          />
        ) : (
          (firstAssetDesktop?.file?.childImageSharp?.gatsbyImageData && firstAssetMobile?.file?.childImageSharp?.gatsbyImageData) && (
            <HeaderImage
              alt={firstAssetDesktop?.alternativeText}
              image={withArtDirection(
                getImage(firstAssetDesktop?.file?.childImageSharp?.gatsbyImageData),
                [
                  {
                    media: '(max-width: 1024px)',
                    image: getImage(
                      firstAssetMobile?.file?.childImageSharp?.gatsbyImageDataMobile
                    ),
                  },
                ]
              )}
              style={{ objectFit: 'contain' }}
            />
          )
        ))}
      </SectionCopy>
    </SectionContainerTop>
  )
}

export default TwoAssetsBanner
