import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

const MainCointainer = styled.section`
  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 40px;
  }
  display: flex;
  padding: 60px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) => (props.BGcolor ? `background-color: ${props.BGcolor};` : 'white')}
  ${(props) => (props.textColor ? `color: ${props.textColor};` : 'white')}
`
const Header = styled.div`
`
const Title = styled.div`
font-family: Objectivity;
font-size: 28px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2;
letter-spacing: -0.14px;
text-align: center;
font-weight: 500;
@media (max-width: 768px) {
  font-size: 16px;
}`
const Details = styled.div`
font-family: Objectivity;
font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2;
letter-spacing: -0.14px;
text-align: center;
font-weight: 400;
padding: 20px 0;
padding-bottom: 0;
max-width: 80%;
@media (max-width: 768px) {
  font-size: 12px;
  max-width: 100%;
}`
const LinksContainer = styled.div`
color: ${(props) => (props.textColor ? `${props.textColor};` : 'black;')};
font-family: Objectivity;
font-size: ${(props) => (props.fontSize ? `${props.fontSize}px;` : '21px;')};
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2;
letter-spacing: -0.14px;
text-align: center;
padding: 20px;
width: 100%;
a {
  color: inherit; 
  text-decoration: underline; 
  transition: color 0.3s ease; /* Add transition for color change */
  margin: 60px; /* Add space between elements */
}

a:hover {
  color: /* Add the color for hover state */;
}

@media (max-width: 768px) {
  font-size: 16px;
  padding: 10px 0;
  a {
    padding: 20px;
    display: block;
    margin: 0px; /* Add space between elements */
  }
}`
const TitleImage = styled(GatsbyImage)`
  width: 400px;
  justify-content: center;
  display: flex;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    width: 250px;
    object-fit: contain;
    padding: 20px;
  }
`
const DisclaimerV2 = ({ data }) => {
  return (
    <MainCointainer BGcolor={data.BGcolor} textColor={data.TextColor}>
      {
        data.DisclaimerImage ? (
          <TitleImage
            loading="lazy"
            objectPosition="center"
            objectFit='contain'
            alt={data.DisclaimerImage?.alternativeText}
            image={withArtDirection(
              getImage(data.DisclaimerImage?.file?.childImageSharp?.gatsbyImageData),
              [
                {
                  media: '(max-width: 768px)',
                  image: getImage(data.DisclaimerImage?.file?.childImageSharp?.gatsbyImageData),
                },
              ]
            )}
          />
        ) : (
          <Header>{data.Title}</Header>
        )
      }
      <Title dangerouslySetInnerHTML={{ __html: data.Description && data.Description }} />
      <LinksContainer textColor={data.TextColor} dangerouslySetInnerHTML={{ __html: data.Links && data.Links }} />
      <Details dangerouslySetInnerHTML={{ __html: data.Details && data.Details }} />
    </MainCointainer >
  )
}

export default DisclaimerV2
