import React from 'react'
import { Slice } from 'gatsby'

const BundleBuilderPage = ({ data }) => {
  return (
    <Slice
        panelData={data}
        alias={`bundle_builder_panel`}
    />
  )
}

export default BundleBuilderPage
