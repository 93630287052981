import React from 'react'
import ImageTextPanel from './image-text-panel'

const PressPanel = ({data}) => {
    return (
        <ImageTextPanel 
            images={data.Items.map(item => ({
                image: item.Logo,
                text: item.Quote
            }))}
        />
    )
}

export default PressPanel