import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'

const EmblaDot = styled.div`
  -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 15px;
  width: 5px;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:after {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
    background-color: rgb(234, 234, 234);
  }

  &.embla__dot--selected:after {
    background-color: rgb(54, 49, 61);
  }
`

export const EmblaCarouselDotButton = (emblaApi) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick
  }
}

export const DotButton = (props) => {
  const { children, ...restProps } = props

  return (
    <EmblaDot type="button" {...restProps}>
      {children}
    </EmblaDot>
  )
}
