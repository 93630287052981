import styled from '@emotion/styled'
import React from 'react'

const TickerItem = styled.h1`
  margin: 0 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  ${(props) => (props.size === 'small' ? 'min-height: 60px' : props.size === 'large' ? 'min-height: 150px;' : 'min-height: 75px;')};
`

const TickerHeading = ({ title, titlePt2, color, BGcolor, size, overlapCompensation }) => {
  const titleSplit = title?.split(',')

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div id={`banner-${title}`} style={{ position: 'absolute', top: -160, height: 1 }} />
      <div className="ticker animate ticker-v2 animate-v2" style={{ margin: 0, backgroundColor: BGcolor, ...(overlapCompensation ? { paddingBottom: 50 } : {}) }}>
        {
          (new Array(Math.ceil(title?.length / 2)).fill(0)).map((_, key) => {
            return titleSplit.map((word, i) => 
              <div className="ticker_list" key={key}>
                <TickerItem style={{ color: color }} size={size}>
                  <span
                    style={{
                      fontFamily: 'Objectivity',
                      fontSize: size === 'small' ? '24px' : size === 'large' ? '60px' : '40px',
                      fontWeight: '500',
                      fontStyle: 'normal',
                      marginRight: '0.4em'
                    }}
                  >
                    {word}{" "}
                  </span>
                </TickerItem>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const TickerV2 = ({ data, overlapCompensation }) => {
  return (
    <div
      style={{
        overflow: 'hidden',
        backgroundColor: data.backgroundColour,
      }}
    >
      <TickerHeading overlapCompensation={overlapCompensation} title={data.TickerText} titlePt2={data?.TickerTextPt2} BGcolor={data.BGcolor} data={data} size={data.TickerSize} color={data.TickerColor} />
    </div>
  )
}

export default TickerV2
