import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { graphql, useStaticQuery } from 'gatsby'
import { createContext, useRef, useContext } from 'react'
import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
  useShopifyCookies,
  ShopifySalesChannel,
  AnalyticsPageType,
} from '@shopify/hydrogen-react'

import { useMixpanel } from '../hooks/useMixpanel'
import { createStore, useStore } from 'zustand'
import {
  updateCheckout,
  validateCheckout,
  createCheckout,
  applyDiscountCode,
} from '../lib/shopify'
import useStockQuery from './useStockQuery'
import {
  stockLookup,
  currencyCodeWithPrefixLookUp,
  currencyOnly,
  currencyCodeLookup,
  analyticsShopData,
  localeMap,
  currencyLookup,
} from '../helpers/constants'

const sendPageView = (analyticsPageData) => {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  }
  sendShopifyAnalytics({
    eventName: AnalyticsEventName.PAGE_VIEW,
    shopifySalesChannel: ShopifySalesChannel.headless,
    payload,
  }).catch((e) => {})
}

const CartContext = createContext()

const getFreightData = () => {
  const data = useStaticQuery(graphql`
    query {
      AU: allAuShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      NZ: allNzShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      US: allUsShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      CA: allCaShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      UK: allUkShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }
    }
  `)
  return (locale, price) => {
    const min = '50'
    const max = '2000'

    const roundUp = (num) => {
      return Math.max(Math.min((Math.round(num / 50) + 1) * 50, max), min)
    }

    const getLookupString = `freight-${roundUp(price || 0)}`

    const { AU, NZ, US, UK, CA } = data
    const lookup = {
      'en-AU': AU.nodes.find((val) => val.sku === getLookupString),
      'en-CA': CA.nodes.find((val) => val.sku === getLookupString),
      'en-NZ': NZ.nodes.find((val) => val.sku === getLookupString),
      'en-GB': UK.nodes.find((val) => val.sku === getLookupString),
      en: US.nodes.find((val) => val.sku === getLookupString),
    }
    const localeVariants = lookup[locale]
    const findVariant = localeVariants

    if (findVariant === undefined || price === 0) {
      return []
    }

    return [
      {
        id: findVariant.shopifyId,
        variantQuantity: 1,
        customAttributes: undefined,
        price: findVariant.price,
      },
    ]
  }
}

const sortObject = (object) => {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})
}

const urlToLocale = (url) => {
  if (url.includes('/au')) {
    return 'en-AU'
  } else if (url.includes('/ca')) {
    return 'en-CA'
  } else if (url.includes('/nz')) {
    return 'en-NZ'
  } else if (url.includes('/uk')) {
    return 'en-GB'
  } else {
    return 'en'
  }
}
const findDiscount = (searchQuery) => {
  const search = queryString.parse(searchQuery)
  if (search.discount) {
    return search.discount
  } else {
    return null
  }
}

const checkoutUpdate = (prev, checkout) => {
  if (!checkout) {
    return prev
  }
  const checkoutLineItems = checkout.lineItems.edges

  let updatedCart = prev.map((cartItem) => {
    // Filter checkout line items that match the cart item
    const matchingCheckoutLineItems = checkoutLineItems.filter(
      (checkoutLineItem) => {
        let customAttributes = {}
        checkoutLineItem.node.customAttributes.forEach(
          (attr) => (customAttributes[attr.key] = attr.value)
        )
        return (
          (checkoutLineItem.node.variant.id === cartItem.id ||
            checkoutLineItem.node.variant.sku === cartItem.id) &&
          (!cartItem.customAttributes ||
            JSON.stringify(sortObject(cartItem.customAttributes || {})) ===
              JSON.stringify(sortObject(customAttributes)))
        )
      }
    )

    // Sum the quantities and discount allocations for matching checkout line items
    const totalQuantity = matchingCheckoutLineItems.reduce(
      (total, item) => total + item.node.quantity,
      0
    )
    const totalDiscountAllocations = matchingCheckoutLineItems.reduce(
      (discounts, item) => [...discounts, ...item.node.discountAllocations],
      []
    )

    return {
      ...cartItem,
      quantity: totalQuantity,
      discountAllocations: totalDiscountAllocations,
    }
  })

  return updatedCart
}

const sendAddToCart = (analyticsPageData) => {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  }
  sendShopifyAnalytics({
    eventName: AnalyticsEventName.ADD_TO_CART,
    shopifySalesChannel: ShopifySalesChannel.headless,
    payload,
  }).catch((e) => {})
}

export default function ShopProvider({
  location,
  children,
  pathname,
  ssrLocale,
  analytics
}) {
  const localeKey = (pathname || location.pathname)?.split('/')[1]
  const locale =
    ssrLocale || localeMap[localeKey] || urlToLocale(location.pathname)
  const mixpanel = useMixpanel()
  const [cookieConsent, setCookieConsent] = useState(false)
  // const [storeLocale, setStoreLocale] = useState(locale)

  useEffect(() => {
    setCookieConsent(window?.consented === true)
  }, [])

  useShopifyCookies({
    hasUserConsent: locale !== 'en-GB' ? true : cookieConsent,
    domain: 'july.com',
  })

  const [stock, setStock] = useStockQuery()

  const calculatePrice = (cart, storeLocale) => {
    return (
      cart?.reduce((acc, item) => {
        return (
          acc +
          ((item.title !== 'Digital Gift Card' &&
            item?.globalProduct[storeLocale]?.price * item?.variantQuantity) ||
            0)
        )
      }, 0) || 0
    )
  }
  const useFreightData = getFreightData()
  const footerRef = useRef()

  const shopStoreRef = useRef()
  if (!shopStoreRef.current) {
    shopStoreRef.current = createStore((set, get) => ({
      footerRef: footerRef,
      mixpanel: mixpanel,
      analytics: analytics,
      location: location,
      locale,
      storeLocale: locale,
      pauseScroll: false,
      ipCountryCode: null,
      personalisingIndex: undefined,
      locationSelectorOpen: false,
      cart: [],
      cartOpen: false,
      menuOpen: false,
      checkoutId: '',
      checkoutUrl: '',
      cartLoading: false,
      checkout: {},
      discount: findDiscount(location.search),
      loaded: false,
      protectionEnabled: true,
      fetchCartID: null,
      collectionOpen: false,
      fetchRef: null,
      stock: stock,
      currencyOnly: currencyOnly[locale],
      currencyCodeWithPrefix: currencyCodeWithPrefixLookUp[locale],
      currencyPrefix: currencyLookup[locale],
      currencyCode: currencyCodeLookup[locale],

      actions: {
        setLocation: (val) =>
          set((state) => ({
            ...state,
            location: val,
          })),
        setLocale: (val) =>
          set((state) => ({
            ...state,
            locale: val,
          })),
        setStoreLocale: (val) => {
          set((state) => {
            return {
              ...state,
              storeLocale: val,
            }
          })
          set({
            currencyOnly: currencyOnly[val],
            currencyCodeWithPrefix: currencyCodeWithPrefixLookUp[val],
            currencyPrefix: currencyLookup[val],
          })
        },
        setPauseScroll: (val) =>
          set((state) => ({
            ...state,
            pauseScroll: val,
          })),
        setIpCountryCode: (val) =>
          set((state) => ({
            ...state,
            ipCountryCode: val,
          })),
        setPersonalisingIndex: (val) =>
          set((state) => ({
            ...state,
            personalisingIndex: val,
          })),
        setLocationSelectorOpen: (val) =>
          set((state) => ({
            ...state,
            locationSelectorOpen: val,
          })),
        setCart: (val) => {
          set((state) => {
            if (val) {
              return {
                ...state,
                cart: val,
              }
            } else {
              return {
                ...state,
                cart: [],
              }
            }
          })
        },
        setCartOpen: (val) =>
          set((state) => {
            if (val) {
              return {
                ...state,
                cartOpen: val,
                menuOpen: false,
              }
            } else {
              return {
                ...state,
                cartOpen: val,
              }
            }
          }),
        setMenuOpen: (val) =>
          set((state) => ({
            ...state,
            menuOpen: val,
          })),
        setCheckoutId: (val) =>
          set((state) => ({
            ...state,
            checkoutId: val,
          })),
        setCheckoutUrl: (val) =>
          set((state) => ({
            ...state,
            checkoutUrl: val,
          })),
        setCartLoading: (val) =>
          set((state) => ({
            ...state,
            cartLoading: val,
          })),
        setCheckout: (val) =>
          set((state) => {
            if (!val.lineItems)
              return {
                ...state,
                checkout: val,
              }
            const checkout = checkoutUpdate(state.cart, val)
            return {
              ...state,
              cart: checkout,
              checkout: val,
            }
          }),
        setDiscount: (val) =>
          set((state) => {
            if (!state.checkout.lineItems)
              return {
                ...state,
                discount: val,
              }
            const checkout = checkoutUpdate(state.cart, val)
            return {
              ...state,
              discount: val,
              cart: checkout,
            }
          }),
        setLoaded: (val) =>
          set((state) => ({
            ...state,
            loaded: val,
          })),
        setProtectionEnabled: (val) => {
          set((state) => ({
            ...state,
            protectionEnabled: val,
          }))
          localStorage.setItem('freight', val.toString())
          if (get().loaded) {
            get().actions.updatedCart()
          }
        },
        setProtectionEnabledState: (val) => {
          set({ protectionEnabled: val })
          if (get().loaded) {
            get().actions.updatedCart()
          }
        },
        setFetchCartID: (val) =>
          set((state) => ({
            ...state,
            fetchCartID: val,
          })),
        setCollectionOpen: (val) =>
          set((state) => ({
            ...state,
            collectionOpen: val,
          })),
        setFetchRef: (val) =>
          set((state) => ({
            ...state,
            fetchRef: val,
          })),
        setStock: (val) =>
          set((state) => ({
            ...state,
            stock: val,
          })),
        tryFetchCartUpdate: async (
          checkoutId,
          cart,
          cartId,
          max_retries = 3,
          current_try = 0
        ) => {
          let resCheckout = await updateCheckout(
            checkoutId,
            cart,
            get().mixpanel.get_distinct_id()
          )

          if (get().fetchRef !== cartId) {
            return null
          }
          if (!resCheckout && current_try < max_retries) {
            await new Promise((resolve) => {
              setTimeout(resolve, 2000)
            })
            resCheckout = await get().actions.tryFetchCartUpdate(
              checkoutId,
              cart,
              cartId,
              max_retries,
              current_try + 1
            )
          }

          return resCheckout
        },
        updateLocaleCheckout: async (checkoutId, newCart) => {
          const cartId = typeof window !== 'undefined' ? Date.now() : 0

          set({
            fetchCartID: cartId,
          })
          set({
            fetchRef: cartId,
          })
          let cart = []
          let localeSpecificCart = newCart
            .map((lineitem) => ({
              id: lineitem?.globalProduct[get().storeLocale]?.id,
              variantQuantity: lineitem.variantQuantity,
              customAttributes: lineitem.customAttributes,
              globalProduct: lineitem.globalProduct,
            }))
            .filter((lineitem) => lineitem.id !== undefined)

          cart = [
            ...localeSpecificCart,
            ...(get().protectionEnabled && localeSpecificCart.length > 0
              ? useFreightData(
                  locale,
                  calculatePrice(localeSpecificCart, get().storeLocale)
                )
              : []),
          ]
          return await get().actions.tryFetchCartUpdate(
            checkoutId,
            cart,
            cartId,
            3,
            0
          )
        },
        clearCart: async () => {
          const updatedCart = []
          set({
            cart: updatedCart,
          })

          const newCheckout = await get().actions.updateLocaleCheckout(
            get().checkoutId,
            updatedCart
          )
          if (newCheckout != null) {
            get().actions.setCheckout(newCheckout)

            localStorage.setItem(
              'checkout_id_v2',
              JSON.stringify([updatedCart, newCheckout])
            )
          }
        },
        initCart: async (initCartData) => {
          if (localStorage.freight) {
            get().actions.setProtectionEnabledState(
              localStorage.freight === 'true'
            )
          }
          let loadedCart = initCartData
          let cartObject
          if (localStorage.checkout_id_v2) {
            cartObject = JSON.parse(localStorage.checkout_id_v2)

            if (!initCartData) {
              if (cartObject[0].id) {
                loadedCart = [cartObject[0]]
              } else if (cartObject[0].length > 0) {
                loadedCart = cartObject[0]
              } else {
                loadedCart = []
              }
            }
          }
          const calPrice = calculatePrice(loadedCart, get().storeLocale)

          if (localStorage.checkout_id_v2 || initCartData) {
            if (
              localStorage.locale !== locale &&
              locale !== undefined &&
              locale !== 'undefined' &&
              !initCartData
            ) {
              localStorage.setItem('locale', locale)
              set({
                storeLocale: locale,
              })
              get().actions.clearCart()
            } else {
              let completedAt = undefined
              let failedValidation = false
              try {
                completedAt = await validateCheckout(cartObject?.[1]?.id)
              } catch (e) {
                failedValidation = true
                console.log(e)
              }
              if (completedAt && !initCartData) {
                get().actions.clearCart()
              } else {
                let freshCart = loadedCart
                  .map((val) => ({
                    ...val,
                    id: val?.globalProduct[get().storeLocale]?.id,
                  }))
                  .filter((lineItem) => lineItem.id !== undefined)
                if (
                  JSON.stringify(freshCart) !== JSON.stringify(loadedCart) ||
                  failedValidation ||
                  initCartData
                ) {
                  const freshCheckout = await createCheckout(
                    [
                      ...freshCart,
                      ...(get().protectionEnabled && freshCart.length > 0
                        ? useFreightData(
                            locale,
                            calculatePrice(freshCart, get().storeLocale)
                          )
                        : []),
                    ],
                    initCartData ? undefined : mixpanel.get_distinct_id()
                  )
                  get().actions.setCart(freshCart)
                  get().actions.setCheckoutId(freshCheckout.id)
                  get().actions.setCheckoutUrl(freshCheckout.webUrl)
                  get().actions.setCheckout(freshCheckout)

                  localStorage.setItem(
                    'checkout_id_v2',
                    JSON.stringify([freshCart, freshCheckout])
                  )
                } else {
                  get().actions.setCart(freshCart)
                  get().actions.setCheckoutId(cartObject[1].id)
                  get().actions.setCheckoutUrl(cartObject[1].webUrl)
                  get().actions.setCheckout(cartObject[1])
                }
              }
            }
          }
          get().actions.setLoaded(true)
        },
        addPersonalisationToItem: async (index, labelData) => {
          let updatedCart = get().cart
          const selectedItem = updatedCart[index]
          selectedItem['globalProduct'] = selectedItem['personalisedVariant']
          selectedItem['id'] = `${selectedItem['id']}-P`
          selectedItem['personalisedVariant'] = undefined
          selectedItem['customAttributes'] = labelData

          get().actions.setCartLoading(true)
          get().actions.setCartOpen(true)
          get().actions.setPersonalisingIndex()
          get().actions.setCart(updatedCart)

          const newCheckout = await get().actions.updateLocaleCheckout(
            get().checkoutId,
            updatedCart
          )
          if (newCheckout != null) {
            get().actions.setCheckout(newCheckout)
            localStorage.setItem(
              'checkout_id_v2',
              JSON.stringify([updatedCart, newCheckout])
            )
            get().actions.setCartLoading(false)
          }
        },
        calculateGiftCard: (cart) => {
          return (
            cart?.reduce((acc, item) => {
              return (
                acc +
                ((item.title === 'Digital Gift Card' &&
                  item?.globalProduct[get().storeLocale]?.price *
                    item?.variantQuantity) ||
                  0)
              )
            }, 0) || 0
          )
        },
        calculatedTotal: () => {
          const total =
            get().checkout?.lineItemsSubtotalPrice?.amount -
            get().actions.calculateGiftCard(get().cart)
          return total
        },
        incrementCartItem: async (item) => {
          get().actions.setCartLoading(true)
          sendAddToCart({
            hasUserConsent:
              locale !== 'en-GB' ? true : window?.consented === true,
            ...analyticsShopData[locale],
          })

          let newCart = []
          get().cart.forEach((cartItem) => {
            if (
              cartItem.id === item.id &&
              ((!cartItem.customAttributes && !item.customAttributes) ||
                JSON.stringify(cartItem.customAttributes) ===
                  JSON.stringify(item.customAttributes))
            ) {
              cartItem.variantQuantity++
            }
            newCart.push(cartItem)
          })
          get().actions.setCart(newCart)
          const newCheckout = await get().actions.updateLocaleCheckout(
            get().checkoutId,
            newCart
          )
          if (newCheckout != null) {
            get().actions.setCheckout(newCheckout)
            localStorage.setItem(
              'checkout_id_v2',
              JSON.stringify([newCart, newCheckout])
            )
            get().actions.setCartLoading(false)
          }
        },
        removeCartItem: async (itemToRemove) => {
          const updatedCart = get().cart.filter(
            (item) =>
              !(
                item.id === itemToRemove.id &&
                (!itemToRemove.customAttributes ||
                  JSON.stringify(itemToRemove.customAttributes) ===
                    JSON.stringify(item.customAttributes))
              )
          )
          get().actions.setCartLoading(true)

          get().actions.setCart(updatedCart)
          if (updatedCart.length === 0) {
            get().actions.setCartOpen(false)
          }

          const newCheckout = await get().actions.updateLocaleCheckout(
            get().checkoutId,
            updatedCart
          )
          if (newCheckout != null) {
            get().actions.setCheckout(newCheckout)
            localStorage.setItem(
              'checkout_id_v2',
              JSON.stringify([updatedCart, newCheckout])
            )
            get().actions.setCartLoading(false)
          }
        },
        decrementCartItem: async (item, removedItem) => {
          get().actions.setCartLoading(true)

          const analytics = get().analytics
          const storeLocale = get().storeLocale
          const checkoutId = get().checkoutId
          
          analytics.track('Product Removed', {
            cart_id: checkoutId,
            product_id: removedItem?.globalProduct?.[storeLocale]?.productId,
            sku: removedItem?.globalProduct?.[storeLocale]?.sku,
            category: removedItem?.product_categories?.[0],
            name: removedItem?.title,
            brand: 'July',
            variant: removedItem?.globalProduct?.[storeLocale]?.title,
            price: removedItem?.globalProduct?.[storeLocale]?.price,
            quantity: removedItem?.variantQuantity,
            url: window.location.href,
            image_url:
              removedItem?.globalProduct?.[storeLocale]?.image?.gatsbyImageData
                ?.images?.fallback?.src,
          })

          if (item.variantQuantity === 1) {
            await get().actions.removeCartItem(item)
          } else {
            let newCart = []
            get().cart.forEach((cartItem) => {
              if (
                cartItem.id === item.id &&
                ((!cartItem.customAttributes && !item.customAttributes) ||
                  JSON.stringify(cartItem.customAttributes) ===
                    JSON.stringify(item.customAttributes))
              ) {
                cartItem.variantQuantity--
              }
              newCart.push(cartItem)
            })

            get().actions.setCart(newCart)
            const newCheckout = await get().actions.updateLocaleCheckout(
              get().checkoutId,
              newCart
            )
            if (newCheckout != null) {
              get().actions.setCheckout(newCheckout)
              localStorage.setItem(
                'checkout_id_v2',
                JSON.stringify([newCart, newCheckout])
              )
            }
            get().actions.setCartLoading(false)
          }
        },
        addToCart: async (addedItem) => {
          const storeLocale = get().storeLocale
          const checkoutId = get().checkoutId
          const productTracking = {
            'Item Id': addedItem.globalProduct?.[storeLocale]?.productId,
            Price: addedItem.globalProduct?.[storeLocale]?.price,
            'Item Name': addedItem.title,
            'Item Type': addedItem.product_categories?.[0],
            Currency: addedItem.currency,
            Quantity: addedItem.variantQuantity,
            'Quick Add Selected': addedItem.quickSelected ? true : false,
          }

          mixpanel.track('Item Added to Cart', productTracking)

          analytics.track('Product Added', {
            cart_id: checkoutId,
            product_id: addedItem.globalProduct?.[storeLocale]?.productId,
            sku: addedItem.globalProduct?.[storeLocale]?.sku,
            category: addedItem.product_categories?.[0],
            name: addedItem.title,
            brand: 'July',
            variant: addedItem.globalProduct?.[storeLocale]?.title,
            price: addedItem.globalProduct?.[storeLocale]?.price,
            quantity: addedItem.variantQuantity,
            url: window.location.href,
            image_url:
              addedItem.globalProduct?.[storeLocale]?.image?.gatsbyImageData
                ?.images?.fallback?.src,
          })

          let newItem, listItems
          get().actions.setCartOpen(true)
          get().actions.setCartLoading(true)

          if (addedItem.constructor.name === 'Array') {
            listItems = addedItem.map((val) => ({
              ...val,
            }))
          } else {
            newItem = {
              ...addedItem,
            }
            listItems = [newItem]
            try {
              document.dispatchEvent(
                new CustomEvent('oke_connect_cart_itemVariantAdded', {
                  detail: {
                    productId: `${addedItem?.globalProduct[storeLocale]?.productId}`,
                    variantId: addedItem.globalProduct[
                      storeLocale
                    ]?.id?.replace('gid://shopify/ProductVariant/', ''),
                    quantity: 1,
                  },
                })
              )

              sendAddToCart({
                hasUserConsent:
                  locale !== 'en-GB' ? true : window?.consented === true,
                ...analyticsShopData[locale],
                pageType: AnalyticsPageType.product,
                resourceId: `gid://shopify/Product/${addedItem?.globalProduct[storeLocale]?.productId}`,
                cartID: get().checkoutId,
                products: [
                  {
                    quantity: 1,
                    productGid: `gid://shopify/Product/${addedItem?.globalProduct[storeLocale]?.productId}`,
                    variantGid: addedItem.globalProduct[storeLocale]?.id,
                    name: addedItem.title,
                    variantName: addedItem.variant,
                    brand: 'July',
                    price: addedItem.globalProduct[storeLocale]?.price,
                    sku: addedItem.globalProduct[storeLocale]?.sku,
                  },
                ],
              })
            } catch (e) {
              console.log('Failed to send analytics of add to cart')
            }
          }

          if (get().cart.length === 0) {
            get().actions.setCart([...listItems])

            localStorage.setItem('locale', locale)

            const createCart = listItems
              .map((val) => ({
                ...val,
                id: val?.globalProduct[storeLocale]?.id,
              }))
              .filter((lineItem) => lineItem.id !== undefined)
            let resCheckout = await createCheckout([
              ...createCart,
              ...(get().protectionEnabled && createCart.length > 0
                ? useFreightData(
                    locale,
                    calculatePrice(createCart, get().storeLocale)
                  )
                : []),
            ])
            if (get().discount) {
              resCheckout = await applyDiscountCode(
                resCheckout.id,
                get().discount
              )
            }

            get().actions.setCheckout(resCheckout)
            get().actions.setCheckoutId(resCheckout.id)
            get().actions.setCheckoutUrl(resCheckout.webUrl)
            get().actions.setCartLoading(false)

            localStorage.setItem(
              'checkout_id_v2',
              JSON.stringify([listItems, resCheckout])
            )
          } else {
            let newCart = [...get().cart]

            listItems.forEach((newMapItem) => {
              let added = false
              newCart.forEach((item, id) => {
                if (
                  item.id === newMapItem.id ||
                  item.id === newMapItem?.globalProduct[storeLocale]?.id
                ) {
                  if (
                    item.customAttributes &&
                    newMapItem.customAttributes &&
                    JSON.stringify(item.customAttributes) ===
                      JSON.stringify(newMapItem.customAttributes)
                  ) {
                    newCart[id].variantQuantity++
                    added = true
                  } else if (!item.customAttributes) {
                    newCart[id].variantQuantity++
                    added = true
                  }
                }
              })
              if (!added) {
                newCart = [...newCart, newMapItem]
              }
            })

            get().actions.setCart(newCart)
            const newCheckout = await get().actions.updateLocaleCheckout(
              get().checkoutId,
              newCart
                .map((val) => ({
                  ...val,
                  id: val?.globalProduct[storeLocale]?.id,
                }))
                .filter((lineItem) => lineItem.id !== undefined)
            )
            if (newCheckout != null) {
              get().actions.setCheckout(newCheckout)
              localStorage.setItem(
                'checkout_id_v2',
                JSON.stringify([newCart, newCheckout])
              )
            }
            get().actions.setCartLoading(false)
          }
        },
        setDiscountCode: async (code) => {
          const { checkoutId, cart } = get()
          if (checkoutId && cart) {
            get().actions.setCartLoading(true)
            await applyDiscountCode(checkoutId, code)
            get().actions.setDiscount(code)

            const newCheckout = await get().actions.updateLocaleCheckout(
              checkoutId,
              cart
            )
            if (newCheckout != null) {
              get().actions.setCheckout(newCheckout)
              localStorage.setItem(
                'checkout_id_v2',
                JSON.stringify([cart, newCheckout])
              )
              get().actions.setCartLoading(false)
            }
          }
        },
        updatedCart: async () => {
          const { checkoutId, cart } = get()
          if (cart.length > 0) {
            get().actions.setCartLoading(true)
            const newCheckout = await get().actions.updateLocaleCheckout(
              checkoutId,
              cart
            )
            if (newCheckout != null) {
              get().actions.setCheckout(newCheckout)
              localStorage.setItem(
                'checkout_id_v2',
                JSON.stringify([cart, newCheckout])
              )
            }
            get().actions.setCartLoading(false)
          }
        },
        calculatePrice: () => {
          const price = useFreightData(
            get().locale,
            calculatePrice(get().cart, get().storeLocale)
          )?.[0]?.price
          return price
        },
      },
    }))
  }

  useEffect(() => {
    const getOOS = async () => {
      try {
        let newStock = {
          'en-AU': null,
          'en-NZ': null,
          'en-GB': null,
          'en': null
        }
        const storeLocale = shopStoreRef.current.getState().storeLocale

        let pullStockPromise = new Promise((res, rej) => {
          fetch(stockLookup[storeLocale], {
            headers: {},
          })
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              return res(data)
            })
            .catch((e) => rej(e))
        }, [])
        newStock[storeLocale] = await pullStockPromise
        shopStoreRef.current.getState().actions.setStock(newStock)
      } catch (e) {
        console.log(e)
      }
    }
    getOOS()
    setInterval(getOOS, 1000 * 60 * 0.5)
  }, [])

  useEffect(() => {
    // set the location on initial load
    if (!shopStoreRef.current.getState().location) {
      shopStoreRef.current.getState().location = location
      sendPageView({
        hasUserConsent:
          shopStoreRef.current.getState().storeLocale !== 'en-GB'
            ? true
            : window?.consented === true,
        ...analyticsShopData[shopStoreRef.current.getState().storeLocale],
      });
    }
    // then make sure dialog is closed on route change
    else if (shopStoreRef.current.getState().location !== location) {
      sendPageView({
        hasUserConsent:
          shopStoreRef.current.getState().storeLocale !== 'en-GB'
            ? true
            : window?.consented === true,
        ...analyticsShopData[shopStoreRef.current.getState().storeLocale],
      });

      shopStoreRef.current.getState().actions.setLocation(location)
    }
  }, [location])

  return (
    <CartContext.Provider value={shopStoreRef.current}>
      {' '}
      {children}{' '}
    </CartContext.Provider>
  )
}

const ShopConsumer = CartContext.Consumer

export { ShopConsumer, CartContext }

export const useShopStoreIncontext = (selector) => {
  const store = useContext(CartContext)
  if (!store) {
    throw new Error('Missing ShopProvider')
  }
  return useStore(store, selector ?? ((state) => state))
}
