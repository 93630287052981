import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react'
import styled from '@emotion/styled'

const GalleryContainer = styled.div`
  background-color: #E79975;
  padding-bottom: 16px;
`

const Gallery = styled.div`
  max-width: 100vw;
  overflow-x: scroll;
  padding-bottom: 16px;
`

const scrollAnimation = `
  @keyframes scroll {
    0% {
      margin-left: -15vw;
    }
    100% {
      margin-left: -150vw;
    }
  }
`;


const ImageContainer = styled.div`
  display: flex;
  gap: 16px;
  animation: scroll 30s linear infinite;
  ${scrollAnimation}

`

const Image = styled(GatsbyImage)`
  width: 30vw;
`

const ImageGallery = ({data}) => {
  return ( 
    <GalleryContainer>
      <Gallery>
        <ImageContainer>
          {
            data?.GalImages?.map(image => {
              return (
                <div>
                  <Image image={getImage(image?.file.childImageSharp.gatsbyImageData)} />
                </div>
              )
            })
          }
        </ImageContainer>
      </Gallery>
    </GalleryContainer>
  );
}
 
export default ImageGallery;