import React from 'react'
import FullBanner from './fullbanner'

const FullBannerV2 = ({data}) => {
    return (
        <FullBanner
            image={data.Media} 
            header={data.Heading} 
            subHeader={data.Body} 
            button={{buttonText: data.CTA, link: data.link}}
        />
    )
}

export default FullBannerV2