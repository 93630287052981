import React from 'react'
import SplitPanel from './split_panel'

// [
//     {
//       image: {
//         alternativeText: '',
//         mime: '',
//         name: '',
//         isStatic: true,
//         url: UpsellItem1
//       }, productTitle: 'Carry On', label: 'SHOP LUGGAGE', to: '/luggage/'
//     },
//     {
//       image: {
//         alternativeText: '',
//         mime: '',
//         name: '',
//         isStatic: true,
//         url: UpsellItem2
//       }, productTitle: 'Carry On', label: 'SHOP TRAVEL BAGS', to: '/travel-bags/'
//     }
//   ]

const SplitPanelV2Products = ({ data }) => {
    return (
        <SplitPanel
            header={data?.Subheading}
            title={data?.Heading}
            body={data?.Body}
            link={data?.link}
            addPersonalisationOptionOn={false}
            color={data?.Theme?.panel_theme?.Background}
            font={'retro'}
            Image={data?.Image}
            reverse={true}
            upsellItem={data?.Products.map(val => {
                return {
                    image: val.Image,
                    label: val.CTA,
                    to: val.link
                }
            })}
            overlapCompensation={data?.overlapCompensation}
        />
    )
}

export default SplitPanelV2Products