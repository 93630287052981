function retryQuery(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryQuery(query, timout), timout)
    }
  }
}

const twq = (b, c) => {
  if (typeof window !== 'undefined') {
    retryQuery(() => {
      window.twq('event', b, c)
    }, 3000)
  }
}
export default twq
