module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":false,"custom":[{"name":["Objectivity"],"file":"/fonts/font.css"},{"name":["mazius"],"file":"/fonts/mazius/stylesheet.css"},{"name":["PPEditorialNew"],"file":"/fonts/PPEditorialNew/stylesheet.css"},{"name":["Roslindale"],"file":"/fonts/Roslindale/stylesheet.css"},{"name":["SackersGothicStd-Heavy"],"file":"/fonts/SackersGothicStd/stylesheet.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
