import React, { useState } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Box = styled.div`
  width: 30%;
  height: 50px;
  margin: 5px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }

  ${(props) =>
    props.isSelected &&
    `
    background-color: #f0f0f0;
    transform: scale(1);
  `}
`;

const OptionSelect = ({ optionsList, setValue }) => {
  const [selected, setSelected] = useState(0);

  const handleOptionClick = (id) => {
    setValue(id);
    setSelected(id);
  };

  return (
    <Container>
      {optionsList.map((option) => (
        <Box
          key={option.id}
          isSelected={selected === option.id}
          onClick={() => handleOptionClick(option.id)}
        >
          {option.name}
        </Box>
      ))}
    </Container>
  );
};

export default OptionSelect;
