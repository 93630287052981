import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

const SaveContextItem = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
`
const SaveContextContainer = styled.div`
  padding: 32px;
  display: flex;
  ${(props) => (props.backgroundColor ? `background: ${props.backgroundColor};` : '#FFFFFF')}

  justify-content: center;
  ${SaveContextItem}:not(:first-of-type) {
    border-left: 2px solid white;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    ${SaveContextItem}:not(:first-of-type) {
      border-top: 2px solid white;
      border-left: none;
    }
  }
`
const SEOH1 = styled.h1`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.35px;
  color: #282828;
  font-size: 32px;
  font-weight: 500;
  line-height: 2.83;
  letter-spacing: -0.64px;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: 0.12px;
  }
`
const SEOP = styled.p`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.35px;
  color: #282828;
  @media (max-width: 768px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.07px;
  }
`
const SEOContainer = styled.div`
  background-color: #f6f3ef;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const BodyCopy = styled.div`
text-align: center;
border: none !important;
line-height: 1.5;
font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight};` : '400;')}
font-family: ${(props) => (props.font ? `${props.font};` : 'Objectivity;')}
font-size: ${(props) => (props.fontSize ? `${props.fontSize}px;` : '16px;')}
color: ${(props) => (props.color ? `${props.color};` : '#000000;')};
max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px;` : '100%;')};

@media (max-width: 768px) {
  font-size: 16px;
  max-width: 500px;
}

& p {
  margin: 16px 0;
}
`
const SEOImage = styled(GatsbyImage)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const SEOTextContainer = styled.div`
  padding: 16px 16px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const StaticHeaderImage = styled.img`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const Header = styled.div`
  text-align: center;
  color: #000000;
  border: none;
  font-size: 24px;
  font-weight: 500;
  margin: 0 32px;
`

const DisclamerImageBanner = ({ data }) => {
  return (
    <>
      {data.SideImage === null ? (
        <SaveContextContainer backgroundColor={data.backgroundColor}>
          <div style={{ clear: 'both' }}>
            <Header>
              {data.Heading}
            </Header>
            <br />
            {data.BodyCopy && (
              <BodyCopy
                font={data.BodyFont}
                fontWeight={data.BodyFontWeight}
                fontSize={data.BodyFontSize}
                color={data.BodyColour}
                maxWidth={data.maxWidth}
                dangerouslySetInnerHTML={{ __html: data.BodyCopy }}
              />
            )}
          </div>
        </SaveContextContainer>
      ) : (
        <SEOContainer>
          <SEOTextContainer>
            <SEOH1> {data.Heading} </SEOH1>
            <SEOP> {data.BodyCopy} </SEOP>
          </SEOTextContainer>
          {data.SideImage?.isStatic ? (
            <StaticHeaderImage src={data.SideImage?.url} />
          ) : (
            <SEOImage
              alt={data.SideImage?.alternativeText || data?.SideImage.name}
              image={withArtDirection(getImage(data.SideImage?.file?.childImageSharp?.gatsbyImageData), [
                {
                  media: '(max-width: 768px)',
                  image: getImage(data.SideImage?.file?.childImageSharp?.gatsbyImageDataMobile),
                },
              ])}
            />
          )}
        </SEOContainer>
      )}
    </>
  )
}

export default DisclamerImageBanner
