async function ShopifyData(query) {
  let domain = process.env.SHOPIFY_STORE_DOMAIN
  let storefrontAccessToken = process.env.SHOPIFY_STOREFRONT_ACCESSTOKEN
  if (localStorage.locale === 'en') {
    domain = process.env.US_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.US_SHOPIFY_STOREFRONT_ACCESSTOKEN
  } else if (localStorage.locale === 'en-NZ') {
    domain = process.env.NZ_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.NZ_SHOPIFY_STOREFRONT_ACCESSTOKEN
  } else if (localStorage.locale === 'en-GB') {
    domain = process.env.UK_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.UK_SHOPIFY_STOREFRONT_ACCESSTOKEN
  } else if (localStorage.locale === 'en-CA') {
    domain = process.env.CA_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.CA_SHOPIFY_STOREFRONT_ACCESSTOKEN
  }

  const URL = `https://${domain}/api/2022-07/graphql.json`

  const options = {
    endpoint: URL,
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  }

  try {
    const data = await fetch(URL, options).then((response) => {
      return response.json()
    })

    return data
  } catch (error) {
    throw new Error('Products not fetched')
  }
}

function transformDataCheckout(cart) {
  let edges = []
  let lineItemsSubtotalPrice = 0
  cart?.lines?.edges?.forEach((item) => {
    let discountAllocations = []
    let customAttributes = []
    item.node?.discountAllocations?.forEach((discount) => {
      discountAllocations.push({
        allocatedAmount: {
          amount: discount.discountedAmount?.amount,
          currencyCode: discount.discountedAmount?.currencyCode
        },
        discountApplication: {
          title: discount.title
        }
      })
    })

    item.node?.attributes?.forEach((attribute) => {
      customAttributes.push({
        key: attribute.key,
        value: attribute.value
      })
    })

    edges.push({
      node: {
        id: item.node.id,
        title: item.node.merchandise?.product?.title,
        quantity: item.node?.quantity,
        variant: {
          id: item.node?.merchandise?.id,
          sku: item.node?.merchandise?.sku,
          price: {
            amount: item.node?.merchandise?.price?.amount,
            currencyCode: item.node?.merchandise?.price?.currencyCode
          },
          title: item.node.merchandise?.title,
          image: {
            src: item.node.merchandise?.product?.images?.edges?.[0]?.node?.src
          }
        },
        unitPrice: {
          amount: item.node.cost?.totalAmount?.amount,
          currencyCode: item.node.cost?.totalAmount?.currencyCode
        },
        customAttributes,
        discountAllocations
      }
    })

    lineItemsSubtotalPrice += parseFloat(item.node.merchandise?.price?.amount * item.node?.quantity)
  })

  const transformData = {
    id: cart?.id,
    webUrl: cart?.checkoutUrl,
    subtotalPrice: cart?.cost?.subtotalAmount,
    lineItemsSubtotalPrice: {
      amount: lineItemsSubtotalPrice?.toString(),
      currencyCode: cart?.cost?.subtotalAmount?.currencyCode
    },
    totalPrice: cart?.cost?.totalAmount,
    lineItems: {
      edges: edges
    },
  };

  return transformData
}

export async function getProductsInCollection() {
  const query = `
  {
    collection(handle: "frontpage") {
      title
      products(first: 25) {
        edges {
          node {
            id
            title
            handle
            priceRange {
              minVariantPrice {
                amount
              }
            }
            images(first: 5) {
              edges {
                node {
                  url
                  altText
                }
              }
            }
          }
        }
      }
    }
  }`

  const response = await ShopifyData(query)

  const allProducts = response.data.collection.products.edges
    ? response.data.collection.products.edges
    : []

  return allProducts
}

export async function getAllProducts() {
  const query = `{
    products(first: 250) {
      edges {
        node {
          handle
          id
        }
      }
    }
  }`

  const response = await ShopifyData(query)

  const slugs = response.data?.products?.edges ? response.data?.products?.edges : []

  return slugs
}

export async function getProduct(handle) {
  const query = `
  {
    product(handle: "${handle}") {
    	collections(first: 1) {
      	edges {
          node {
            products(first: 5) {
              edges {
                node {
                  priceRange {
                    minVariantPrice {
                      amount
                    }
                  }
                  handle
                  title
                  id
                  images(first: 5) {
                    edges {
                      node {
                        url
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
        }
    	}
      id
      title
      handle
      description
      images(first: 5) {
        edges {
          node {
            url
            altText
          }
        }
      }
      options {
        name
        values
        id
      }
      variants(first: 25) {
        edges {
          node {
            selectedOptions {
              name
              value
            }
            image {
              url
              altText
            }
            title
            id
            availableForSale
            priceV2 {
              amount
            }
          }
        }
      }
    }
  }`

  const response = await ShopifyData(query)

  const product = response.data?.product ? response.data?.product : []

  return product
}

export async function createCheckout(lineItems, distinctId) {
  let attributes = []
  if (distinctId !== undefined) {
    attributes.push(`{ key: "distinctId", value: "${distinctId}" }`)
  }

  const attributesString = attributes.join(', ')
  const lineItemsObject = lineItems.map((item) => {
    if (item.customAttributes !== undefined && distinctId !== undefined) {
      item.customAttributes['distinctId'] = distinctId
    }
    return `{
      merchandiseId: "${item.id}",
      quantity:  ${item.variantQuantity}
      ${item.customAttributes !== undefined
        ? `, attributes: [ ${Object.keys(item.customAttributes)
          .map(
            (key) =>
              `{ key: "${key}", value: "${item.customAttributes[key]}" }`
          )
          .join(', ')} ]`
        : ''
      }
    }`
  })


  const query = `
  mutation {
    cartCreate(input: {
      lines: [${lineItemsObject}],
      attributes: [${attributesString}]
    }) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        totalQuantity
        lines(first: 25) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  sku
                  price {
                    amount
                  }
                  product {
                    title
                    handle
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
              cost {
                totalAmount {
                  amount
                }
                compareAtAmountPerQuantity {
                  amount
                }
              }
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
                ... on CartCustomDiscountAllocation {
                  title
                }
              }
            }
          }
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
          }
          checkoutChargeAmount{
            amount
          }
        }
      }
    }
  }`;

  const response = await ShopifyData(query)

  const cart = response.data.cartCreate.cart
    ? response.data.cartCreate.cart
    : []

  return transformDataCheckout(cart)
}

function getCartLines() {
  const cartLines = JSON.parse(localStorage.getItem('checkout_id_v2'))?.[1].lineItems.edges
  return cartLines.map(edge => edge.node);
}

async function removeLineItems(cartId, lineItems) {

  const lineIdsString = lineItems.map(item => `"${item.id}"`).join(", ");

  const removeLinesQuery = `
  mutation  {
    cartLinesRemove(cartId: "${cartId}", lineIds: [${lineIdsString}]) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        totalQuantity
        lines(first: 25) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  sku
                  price {
                    amount
                  }
                  product {
                    title
                    handle
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
              cost {
                totalAmount {
                  amount
                }
                compareAtAmountPerQuantity {
                  amount
                }
              }
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
                ... on CartCustomDiscountAllocation {
                  title
                }
              }
            }
          }
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
          }
          checkoutChargeAmount{
            amount
          }
        }
      }
    }
  }`;

  const response = await ShopifyData(removeLinesQuery);
  if (response.errors) {
    return;
  }

  const cart = response.data?.cartLinesRemove?.cart ?? null

  return transformDataCheckout(cart)
}

async function addLineItems(cartId, lineItems) {
  const lineItemsString = lineItems.map((item) => {
    return `{
      merchandiseId: "${item.id}",
      quantity:  ${item.variantQuantity}
      ${item.customAttributes !== undefined
        ? `, attributes: [ ${Object.keys(item.customAttributes)
          .map(
            (key) =>
              `{ key: "${key}", value: "${item.customAttributes[key]}" }`
          )
          .join(', ')} ]`
        : ''
      }
    }`
  })

  const query = `
    mutation {
    cartLinesAdd(cartId: "${cartId}", lines: [${lineItemsString}]) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        totalQuantity
        lines(first: 25) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  sku
                  price {
                    amount
                  }
                  product {
                    title
                    handle
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
              cost {
                totalAmount {
                  amount
                }
                compareAtAmountPerQuantity {
                  amount
                }
              }
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
                ... on CartCustomDiscountAllocation {
                  title
                }
              }
            }
          }
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
          }
          checkoutChargeAmount{
            amount
          }
        }
      }
    }
  }`;

  const response = await ShopifyData(query);
  const cart = response?.data?.cartLinesAdd?.cart ?? null

  return transformDataCheckout(cart)
}

async function updateLineItems(cartId, lineItems) {
  const lineItemsString = lineItems.map((item) => {
    return `{
      id: "${item.nodeId}",
      merchandiseId: "${item.id}",
      quantity:  ${item.variantQuantity}
      ${item.customAttributes !== undefined
        ? `, attributes: [ ${Object.keys(item.customAttributes)
          .map(
            (key) =>
              `{ key: "${key}", value: "${item.customAttributes[key]}" }`
          )
          .join(', ')} ]`
        : ''
      }
    }`
  })

  const query = `
    mutation {
    cartLinesUpdate(cartId: "${cartId}", lines: [${lineItemsString}]) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        totalQuantity
        lines(first: 25) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  sku
                  price {
                    amount
                  }
                  product {
                    title
                    handle
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
              cost {
                totalAmount {
                  amount
                }
                compareAtAmountPerQuantity {
                  amount
                }
              }
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
                ... on CartCustomDiscountAllocation {
                  title
                }
              }
            }
          }
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
          }
          checkoutChargeAmount{
            amount
          }
        }
      }
    }
  }`;

  const response = await ShopifyData(query);
  const cart = response?.data?.cartLinesUpdate?.cart ?? null

  return transformDataCheckout(cart)
}

export async function updateCheckout(id, lineItems) {
  const cartLines = getCartLines(id);
  const lineIds = new Set(lineItems.map(item => item.id));
  const addItems = []
  const updateItems = []

  const packageLineItem = lineItems.find(item => item.price);
  const packageCartLineIndex = cartLines.findIndex(line => line.title === "Premium Package Protection");
  if (packageLineItem && packageCartLineIndex > -1) {
    cartLines[packageCartLineIndex].variant.id = packageLineItem.id
  }

  const removeItems = cartLines.filter(line => !lineIds.has(line.variant?.id));
  const cartLineIds = new Set(cartLines.map(line => line.variant?.id));

  lineItems.forEach(item => {
    if (!cartLineIds.has(item.id)) {
      addItems.push(item)
    } else {
      const filteredLines = cartLines.filter(line => line.variant?.id === item.id);
      const lastLine = filteredLines[filteredLines.length - 1]?.id;
      item.nodeId = lastLine ?? null;
      item.variantQuantity = filteredLines.length > 1 ? item.variantQuantity - (filteredLines.length - 1) : item.variantQuantity
      updateItems.push(item)
    }
  })

  let cart = null;
  if (removeItems.length > 0) {
    cart = await removeLineItems(id, removeItems);
  }

  if (updateItems.length > 0) {
    cart = await updateLineItems(id, updateItems);
  }

  if (addItems.length > 0) {
    cart = await addLineItems(id, addItems);
  }

  return cart;
}

export async function recursiveCatalog(cursor = '', initialRequest = true) {
  let data

  if (cursor !== '') {
    const query = `{
      products(after: "${cursor}", first: 250) {
        edges {
          cursor
          node {
            id
            handle
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }`

    const response = await ShopifyData(query)
    data = response.data.products.edges ? response.data.products.edges : []

    if (response.data.products.pageInfo.hasNextPage) {
      const num = response.data.products.edges.length
      const cursor = response.data.products.edges[num - 1].cursor

      return data.concat(await recursiveCatalog(cursor))
    } else {
      return data
    }
  } else {
    const query = `{
      products(first: 250) {
        edges {
          cursor
          node {
            id
            handle
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
    `

    const response = await ShopifyData(query)
    data = response.data.products.edges ? response.data.products.edges : []

    if (response.data.products.pageInfo.hasNextPage) {
      const num = response.data.products.edges.length
      const cursor = response.data.products.edges[num - 1].cursor

      return data.concat(await recursiveCatalog(cursor))
    } else {
      return data
    }
  }
}

export async function applyDiscountCode(id, code) {
  const query = `
  mutation {
    cartDiscountCodesUpdate(input: {cartId: "${id}", discountCode: "${code}"}) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        lines(first: 25) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  sku
                  price {
                    amount
                  }
                  product {
                    title
                    handle
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                  }
                }
              }
              cost {
                totalAmount {
                  amount
                }
                compareAtAmountPerQuantity {
                  amount
                }
              }
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
                ... on CartCustomDiscountAllocation {
                  title
                }
              }
            }
          }
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
          }
          checkoutChargeAmount{
            amount
          }
        }
      }
    }
  }`;

  const response = await ShopifyData(query)
  const cart = response?.data?.cartDiscountCodesUpdate?.cart ?? null

  return transformDataCheckout(cart)
}


export async function validateCheckout(id) {
  const query = `
    query {
      node(id: "${id}"){
          id ... on Cart {
              id
          }
      }
    }
  `

  const response = await ShopifyData(query)

  return response?.data?.node?.id ? false : true
}
