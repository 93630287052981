import styled from '@emotion/styled'
import { default as React } from 'react'

const DetailsContainer = styled.div`
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor};` : '#f6f3ef;')};
`
const DetailsHeader = styled.h2`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.17px;
  text-align: center;
  color: #000;
  padding: 24px;
  @media (max-width: 768px) {
    height: auto; /* Changed height to auto */
    flex-wrap: wrap; /* Added flex-wrap */
  }
`
const DetailsSubHeader = styled.h3`
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.17px;
  text-align: center;
  color: #000;
  padding: 16px;
  padding-top: 0;
  @media (max-width: 768px) {
    height: auto; /* Changed height to auto */
    flex-wrap: wrap; /* Added flex-wrap */
  }
`
const DetailSectionsContaniner = styled.div`
  height: calc(40vh - 120px);
  font-family: Objectivity;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 64px;
  overflow: hidden;
  height: 100%;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    height: auto;
    flex-wrap: wrap;
    padding: 0;
  }
`
const DetailSectionImage = styled.img`
  margin: 10px;
  height: 200px;
  padding-bottom: 16px;
  aspect-ratio: auto 1 / 1; 
  object-fit: contain;
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    height: 150px;
  }
`
const DetailSectionBody = styled.div`
  opacity: 1;
  font-family: Objectivity;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: #000000;
  font-weight: 500;
  padding: 16px 0;
`
const DetailSectionHeader = styled.div`
  opacity: 1;
  font-family: Objectivity;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: #000000;
  font-weight: 500;
  padding: 16px 0;

  @media (max-width: 768px) {
   height: 100px;
   display: flex;
   padding: 16px;
   align-items: center;
   font-size: 15px;
  }
`
const DetailSectionConatiner = styled.div`
  padding: 0 32px;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex: 1 0 100%; /* Changed flex value */
    max-width: 50%; /* Added max-width */
    box-sizing: border-box; /* Added box-sizing */
    padding: 0 16px;
  }
  @media (min-width: 769px) {
    width: 30vw;
  }
`

const ItemDetailSection = ({ data }) => {
  return data.map((val, key) => {
    return (
      <DetailSectionConatiner key={key}>
        <DetailSectionImage src={val.image} alt={val.alt} ImageHeight={val.ImageHeight} />
        <div>
          <DetailSectionHeader backgroundColor={data.backgroundColor}> {val.title} </DetailSectionHeader>
          {
            val.body && (
              <DetailSectionBody> {val.body} </DetailSectionBody>
            )
          }
        </div>
      </DetailSectionConatiner>
    )
  })
}
const DetailsSection = ({ data }) => {
  return (
    <DetailsContainer backgroundColor={data.backgroundColor}>
      {' '}
      <DetailsHeader>{data.MainHeading}</DetailsHeader>{' '}
      {data?.SubHeading && (
        <DetailsSubHeader dangerouslySetInnerHTML={{ __html: data.SubHeading }} />
      )}
      <DetailSectionsContaniner>
        {data?.ProductDetailItems.map((val, key) => (
          <ItemDetailSection
            key={key}
            data={[
              {
                image: val.TileImage?.file?.publicURL,
                title: val.Heading,
                body: val.Copy,
                ImageHeight:
                  data.ImageHeight === 'Large'
                    ? 80
                    : data.ImageHeight === 'Medium'
                      ? 60
                      : data.ImageHeight === 'Small'
                        ? 40
                        : 60,
              },
            ]}
          />
        ))}
      </DetailSectionsContaniner>
    </DetailsContainer>
  )
}

export default DetailsSection
