import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

const DetailsContainer = styled.div`
  background-color: #f6f3ef;
`
const DetailsHeader = styled.h2`
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 200px;
  letter-spacing: -1.17px;
  text-align: center;
  ${(props) => props.textColor ? `color: ${props.textColor};` : 'color: #000000;'};
`
const DetailSectionsContaniner = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 64px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 0 16px;
  }
`
const DetailSectionImage = styled(GatsbyImage)`
  width: 100%;
  background: white;
  aspect-ratio: 1;
`
const DetailSectionBody = styled.div`
  opacity: 0.7;
  font-family: Objectivity;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  padding: 48px 0;
  ${(props) => (props.color ? `color: ${props.color}` : '')}
`
const DetailSectionConatiner = styled.div`
  padding: 0 32px;
  width: 25%;
  @media (max-width: 768px) {
    padding: 0px;
    width: calc(100% - 32px);
  }
`

const DetailSection = ({ data, key }) => {
  return data?.map((section) => {
    const { image } = section;
    if (!image) {
      return null;
    }
    return (
      <DetailSectionConatiner key={`image-${section.copy}`}>
        <DetailSectionImage alt={section?.alt || section?.copy} image={section.image} />
        <DetailSectionBody> {section.copy} </DetailSectionBody>
      </DetailSectionConatiner>
    )
  })
}
const DetailsSection = ({ data }) => {
  return (
    <DetailsContainer style={{ backgroundColor: data.backgroundColor, color: data.textColor }}>
      {' '}
      <DetailsHeader textColor={data.textColor}> {data.Heading} </DetailsHeader>{' '}
      <DetailSectionsContaniner>
        <DetailSection
          data={data.DetailItems?.map((val, index) => ({
            key: index,
            copy: val.DetailsText,
            image: withArtDirection(getImage(val.DetailImage?.file?.childImageSharp?.gatsbyImageData), [
              {
                media: '(max-width: 768px)',
                image: getImage(val.DetailImage?.file?.childImageSharp?.gatsbyImageDataMobile),
              },
            ]),
            alt: val.DetailImage?.alternativeText,
          }))}
        />
      </DetailSectionsContaniner>
    </DetailsContainer>
  )
}

export default DetailsSection
