import styled from '@emotion/styled'
import React, { Fragment } from 'react'

const MainDetailsContainer = styled.div`
  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor};`
      : '#f6f3ef'};
`
const DetailSectionsContaniner = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  align-items: center;
  background-color: ${(props) => (props.BGcolor ? props.BGcolor : 'white;')};
  padding: 32px 0;
  padding-top: 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
    ${(props) => (props.mobile2x2 ? 'display: grid;' : '')};
    padding: 0;
  }
`

const DetailSectionsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  align-items: center;
  background-color: ${(props) => (props.BGcolor ? props.BGcolor : 'white;')};
  color: ${(props) => (props.color ? props.color : 'black;')};
  padding: 32px 10%;
  text-align: center;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 18px;
    flex-wrap: wrap;
    height: auto;
    padding: 32px;
  }
`

const Header = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  opacity: 1;
  letter-spacing: -0.71px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : 'black;')};
  @media (min-width: 769px) {
    font-style: bold;
    font-size: 28px;
  }
`
const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  opacity: 1;
  letter-spacing: -0.71px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : 'black;')};
  @media (min-width: 769px) {
    font-style: bold;
  }
  @media (max-width: 768px) {
    margin: 16px;
    font-size: 14px;
  }
`
const AdditionalPromo = styled.div`
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  opacity: 1;
  letter-spacing: -0.71px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : 'red;')};
  background: ${(props) => (props.BGcolor ? props.BGcolor : ';')};
  max-width: 250px;
  padding: 15px 35px;
  margin-top: 20px;
  border-radius: 30px;
`
const SingleDetailSection = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  border: ${(props) => props.BGcolor && props.BGcolor} 2px solid;

  padding: 16px;
  margin: 30px;
  border-radius: 49px;
  @media (max-width: 768px) {
    margin: 16px;
  }
`

const ContainerSection = styled.div`
justify-content: center;

  @media (min-width: 769px) {
    border-right: 2px solid ${(props) => props.BGcolor && props.BGcolor}; /* Adjust the width as needed */
    width: 30vw;
    &:last-child {
      border-right: none; /* Remove border for the last element */
    }
  }

  @media (max-width: 768px) {
    width: 80%;
    border-bottom: 2px solid ${(props) => props.BGcolor && props.BGcolor}; /* Adjust the width as needed */
    &:last-child {
      border-bottom: none; /* Remove border for the last element */
    }
  }
}
`

const DetailSection = ({ data, color, BGcolor }) => {
  return data.map((section, index) => {
    return (
      <SingleDetailSection
        index={index}
        key={index}
        color={color}
        BGcolor={color}
      >
        <Fragment key={`detail-section-${section.title}`}>
          <Header color={color}> {section.title} </Header>
          {section?.promo?.AdditionalPromo && (
            <AdditionalPromo color={BGcolor} BGcolor={color}>
              {' '}
              {section.promo.AdditionalPromo}{' '}
            </AdditionalPromo>
          )}
        </Fragment>
      </SingleDetailSection>
    )
  })
}

const FeatureDetailsV2 = ({ data }) => {
  return (
    <MainDetailsContainer
      backgroundColor={data.backgroundColor}
      title={data.Title}
    >
      {data.Title && (
        <DetailSectionsTitle BGcolor={data.BGcolor} color={data.TextColor}>
          <span style={{ maxWidth: 800 }}>{data.Title}</span>
        </DetailSectionsTitle>
      )}
      <DetailSectionsContaniner BGcolor={data.BGcolor}>
        {data?.TextFeatureItems?.map((section, index) => (
          <ContainerSection
            key={`feature-details-item-${section.Header}`}
            BGcolor={data.TextColor}
          >
            <DetailSection
              BGcolor={data.BGcolor}
              color={data.TextColor}
              data={[
                {
                  title: <>{section.Header}</>,
                  desc: <>{section.Description}</>,
                  promo: <>{section.AdditionalPromo}</>,
                  index: index,
                },
              ]}
            />
            <Description color={data.TextColor}>
              {' '}
              {section.Description}{' '}
            </Description>
          </ContainerSection>
        ))}
      </DetailSectionsContaniner>
    </MainDetailsContainer>
  )
}

export default FeatureDetailsV2
